import { OnInit, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ClonePlannerCaseBatchCommand, LongRunningTaskInfo } from "@appmodels";
var BatchCloneComponent = /** @class */ (function () {
    /**
     *
     */
    function BatchCloneComponent() {
        this.batchCloneForm = new FormGroup({
            caseId: new FormControl('', Validators.required),
            newTitle: new FormControl(''),
            isExcludeFromAnalytics: new FormControl(true, Validators.required),
            usersEmails: new FormControl('', Validators.required),
        });
        this.onBatchClone = new EventEmitter();
    }
    Object.defineProperty(BatchCloneComponent.prototype, "caseCloneBatchInfo", {
        get: function () {
            return this._caseCloneBatchInfo;
        },
        set: function (value) {
            this._caseCloneBatchInfo = value;
            if (!value)
                return;
            this.lastCaseCloneBatchInfo = value;
            if (value.isCompleted) {
                this.requestInProgress = false;
                this._caseCloneBatchInfo = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    BatchCloneComponent.prototype.ngOnInit = function () {
    };
    BatchCloneComponent.prototype.batchClone = function () {
        this.requestInProgress = true;
        this.lastCaseCloneBatchInfo = null;
        var caseId = this.batchCloneForm.controls['caseId'].value + '';
        //this.batchCloneForm.controls['caseId'].setValue('');
        var newTitle = this.batchCloneForm.controls['newTitle'].value + '';
        var isExcludeFromAnalytics = this.batchCloneForm.controls['isExcludeFromAnalytics'].value;
        var userEmailsString = this.batchCloneForm.controls['usersEmails'].value + '';
        console.log(userEmailsString);
        var userEmails = userEmailsString.split(/[\s,]+/);
        var clonePlannerCaseBatchCommand = new ClonePlannerCaseBatchCommand();
        clonePlannerCaseBatchCommand.caseId = caseId;
        clonePlannerCaseBatchCommand.newTitle = newTitle;
        clonePlannerCaseBatchCommand.isExcludeFromAnalytics = isExcludeFromAnalytics;
        clonePlannerCaseBatchCommand.newOwnersEmails = userEmails;
        this.onBatchClone.emit(clonePlannerCaseBatchCommand);
    };
    return BatchCloneComponent;
}());
export { BatchCloneComponent };
