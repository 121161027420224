import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Language, Speciality, PlannerUsersAvailableModules, PlannerModuleType } from '@appmodels';
import { ObjectMapper } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/en';
import { locale as russian } from '../../../i18n/ru';
import * as moment from 'moment';
var PaidModulesFormDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<PaidModulesFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    function PaidModulesFormDialogComponent(matDialogRef, _data, _formBuilder, _cdr, _fuseTranslationLoaderService) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._formBuilder = _formBuilder;
        this._cdr = _cdr;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.moduleTypeEnum = PlannerModuleType;
        this.specialityEnum = Speciality;
        this.languageEnum = Language;
        this.showPasswordFields = false;
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        this._unsubscribeAll = new Subject();
        // Set the defaults
        this.action = _data.action;
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit user paid module';
            this.paidModule = tslib_1.__assign({}, _data.paidModule);
        }
        else {
            this.dialogTitle = 'Add user paid module';
            this.paidModule = new PlannerUsersAvailableModules();
        }
        this.paidModuleForm = this.createPaidModuleForm(this.paidModule);
        this.onSearchQueryChanged = new EventEmitter();
    }
    Object.defineProperty(PaidModulesFormDialogComponent.prototype, "users", {
        set: function (value) {
            this.foundUsers = value;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    PaidModulesFormDialogComponent.prototype.ngOnInit = function () {
        // this.userForm.get('password').setValue('');
        // this.userForm.get('passwordConfirm').setValue('');
    };
    PaidModulesFormDialogComponent.prototype.onSave = function () {
        var _this = this;
        ObjectMapper.mapFormGroupToObject(this.paidModuleForm, this.paidModule);
        if (this.action === 'new') {
            var selectedUser = this.foundUsers.find(function (user) { return user.email == _this.paidModule.userEmail; });
            this.paidModule.userId = selectedUser.id;
        }
        var dateObj = moment(this.paidModule.startDate);
        var date = moment();
        date.set({ year: dateObj.year(), month: dateObj.month(), date: dateObj.date() });
        this.paidModule.startDate = date;
        this.matDialogRef.close(['save', this.paidModule]);
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    PaidModulesFormDialogComponent.prototype.createPaidModuleForm = function (paidModule) {
        if (paidModule === void 0) { paidModule = null; }
        var form = this._formBuilder.group({
            userEmail: [{ value: '', disabled: this.action === 'new' ? false : true }, [Validators.required, Validators.email]],
            moduleType: ['', Validators.required],
            startDate: ['', Validators.required],
            daysAllocated: [''],
            hospital: [''],
        });
        if (paidModule) {
            ObjectMapper.mapObjectToFormGroup(paidModule, form);
        }
        return form;
    };
    PaidModulesFormDialogComponent.prototype.onSearchQuery = function (searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    };
    PaidModulesFormDialogComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return PaidModulesFormDialogComponent;
}());
export { PaidModulesFormDialogComponent };
