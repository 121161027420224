import * as tslib_1 from "tslib";
var PlannerCaseCommand = /** @class */ (function () {
    function PlannerCaseCommand() {
        this.title = null;
        this.patientName = null;
        this.patientCode = null;
        this.surgicalDate = null;
        this.operationType = null;
        this.sceneType = null;
        this.serializedData = null;
        this.isAllowAll = null;
        this.isArchived = null;
        this.isExcludeFromAnalytics = null;
        this.medicalReferalsType = null;
        this.gender = null;
        this.service = null;
        this.procedures = null;
        this.birthday = null;
        this.surgicalSide = null;
        this.scanCenter = null;
        this.shipment = null;
        this.description = null;
    }
    return PlannerCaseCommand;
}());
export { PlannerCaseCommand };
var CreatePlannerCaseCommand = /** @class */ (function (_super) {
    tslib_1.__extends(CreatePlannerCaseCommand, _super);
    function CreatePlannerCaseCommand() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ownerId = null;
        return _this;
    }
    return CreatePlannerCaseCommand;
}(PlannerCaseCommand));
export { CreatePlannerCaseCommand };
var UpdatePlannerCaseCommand = /** @class */ (function (_super) {
    tslib_1.__extends(UpdatePlannerCaseCommand, _super);
    function UpdatePlannerCaseCommand() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.id = null;
        return _this;
    }
    return UpdatePlannerCaseCommand;
}(PlannerCaseCommand));
export { UpdatePlannerCaseCommand };
