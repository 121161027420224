import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { of, forkJoin } from "rxjs";
import { filter, take } from "rxjs/operators";
import { SetManagementOrganizationByName, SetManagementOrganizationIdSuccess } from "../store/actions/auth.actions";
import { selectManagementOrganizationId } from "../store/selectors/auth.selector";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
var RegisterResolver = /** @class */ (function () {
    function RegisterResolver(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    RegisterResolver.prototype.resolve = function (route, state) {
        var routeParams = route.params;
        this.logger.info('Register route resolver: ', route.params);
        var obsCollection = [];
        var organization = routeParams.organization ? routeParams.organization.trim() : null;
        if (!organization) {
            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(filter(function (organizationId) { return organizationId != null; }), take(1)));
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationIdSuccess(1))));
        }
        else {
            obsCollection.push(this._store.select(selectManagementOrganizationId).pipe(filter(function (organizationId) { return organizationId != null; }), take(1)));
            obsCollection.push(of(this._store.dispatch(new SetManagementOrganizationByName(organization))));
        }
        return forkJoin(obsCollection);
    };
    RegisterResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterResolver_Factory() { return new RegisterResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: RegisterResolver, providedIn: "root" });
    return RegisterResolver;
}());
export { RegisterResolver };
