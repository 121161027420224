import * as tslib_1 from "tslib";
import { EMessagesActions, GetCaseMessagesSuccess, AddCaseMessageSuccess, NewMessageFromServerSuccess } from '../actions/messages.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { CaseReceivedNewMessageSuccess } from '../actions/cases.actions';
import { switchMap, take, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectSelectedCase } from '../selectors/cases.selector';
import { of } from 'rxjs';
import { MessagesService } from 'app/core/services/planner/messages.service';
import { PlannerMessage } from '@appmodels';
import { selectCurrentUser } from '../selectors/users.selectors';
import { NewNotification } from '../actions/notifications.actions';
import { AuthenticationService } from '@appservices';
import { NGXLogger } from 'ngx-logger';
import { selectCaseMessages } from '../selectors/messages.selector';
var MessagesEffects = /** @class */ (function () {
    function MessagesEffects(_messagesService, _authenticationService, _actions$, _store, _logger) {
        var _this = this;
        this._messagesService = _messagesService;
        this._authenticationService = _authenticationService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.getCaseMessages$ = this._actions$.pipe(ofType(EMessagesActions.GetCaseMessages), switchMap(function (action) {
            _this._logger.info("GetCaseMessages, is reload: " + action.isReload);
            if (action.isReload) {
                return of([]).pipe(map(function () {
                    return { caseId: action.caseId, existedMessagesCount: 0, isReload: action.isReload };
                }));
            }
            else {
                return _this._store.pipe(select(selectCaseMessages), take(1), map(function (messages) {
                    return { caseId: action.caseId, existedMessagesCount: messages ? messages.length : 0, isReload: action.isReload };
                }));
            }
        }), switchMap(function (getMessagesRequest) {
            var skip = getMessagesRequest.isReload ? 0 : getMessagesRequest.existedMessagesCount; //? existedCases.length : 0;
            var take = 20;
            return _this._messagesService.getCaseMessages(getMessagesRequest.caseId, skip, take).pipe(map(function (messages) { return { messages: messages, isReload: getMessagesRequest.isReload }; }));
        }), switchMap(function (messagesResult) { return of(new GetCaseMessagesSuccess(messagesResult.messages, messagesResult.isReload)); }));
        this.addCaseMessage$ = this._actions$.pipe(ofType(EMessagesActions.AddCaseMessage), 
        //map(action => action.payload),
        withLatestFrom(this._store.select(selectSelectedCase)), map(function (_a) {
            var addMessageArgs = _a[0], selectedCase = _a[1];
            addMessageArgs.messageCreateCommand.PlannerCaseId = selectedCase.id;
            return addMessageArgs;
        }), switchMap(function (addMessageArgs) {
            return _this._messagesService.addCaseMessage(addMessageArgs.messageCreateCommand).pipe(withLatestFrom(_this._store.select(selectCurrentUser), of(addMessageArgs)));
        }), map(function (_a) {
            var newMessageId = _a[0], currentUser = _a[1], addMessageArgs = _a[2];
            var newMessage = new PlannerMessage();
            newMessage.id = newMessageId;
            newMessage.title = addMessageArgs.messageCreateCommand.Title;
            newMessage.text = addMessageArgs.messageCreateCommand.Text;
            newMessage.userNameFrom = currentUser.userName;
            if (addMessageArgs.messageFile) {
                newMessage.messageFile = addMessageArgs.messageFile;
            }
            newMessage.creationDate = new Date();
            return { newMessage: newMessage, caseId: addMessageArgs.messageCreateCommand.PlannerCaseId };
        }), switchMap(function (newMessageResult) { return [
            new AddCaseMessageSuccess(newMessageResult.newMessage)
        ]; }));
        this.newMessageFromServer$ = this._actions$.pipe(ofType(EMessagesActions.NewMessageFromServer), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedCase)), map(function (_a) {
            var newMessageNotification = _a[0], selectedCase = _a[1];
            var isFromAnotherConnectionApp = _this._authenticationService.appConnectionUid != newMessageNotification.senderAppConnectionUid;
            if (isFromAnotherConnectionApp) {
                _this._store.dispatch(new NewNotification({ type: 'NewMessage', message: newMessageNotification.newMessage.userNameFrom + ": " + newMessageNotification.newMessage.text,
                    action: 'Link', additionalParams: { caseId: newMessageNotification.caseId } }));
            }
            var isFromCurrentCase = selectedCase && selectedCase.id == newMessageNotification.caseId;
            var isNeedToAddToCurrentCaseMessages = isFromAnotherConnectionApp && isFromCurrentCase;
            newMessageNotification.newMessage.isNew = true;
            // console.log(newMessageNotification);
            return { newMessageNotification: newMessageNotification, isNeedToAddToCurrentCaseMessages: isNeedToAddToCurrentCaseMessages };
        }), switchMap(function (newMessageNotificationResult) { return [
            new CaseReceivedNewMessageSuccess(newMessageNotificationResult.newMessageNotification.caseId, newMessageNotificationResult.newMessageNotification.newMessage, _this._authenticationService.appConnectionUid != newMessageNotificationResult.newMessageNotification.senderAppConnectionUid),
            new NewMessageFromServerSuccess(newMessageNotificationResult.newMessageNotification, newMessageNotificationResult.isNeedToAddToCurrentCaseMessages)
        ]; }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesEffects.prototype, "getCaseMessages$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesEffects.prototype, "addCaseMessage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesEffects.prototype, "newMessageFromServer$", void 0);
    return MessagesEffects;
}());
export { MessagesEffects };
