import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserType } from '../models/planner/enums/plannerCaseEnums';
import { AuthGuard } from './auth.guard';
import * as i0 from "@angular/core";
import * as i1 from "./auth.guard";
import * as i2 from "@angular/router";
import * as i3 from "../services/authentication.service";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(authGuard, router, authenticationService) {
        this.authGuard = authGuard;
        this.router = router;
        this.authenticationService = authenticationService;
    }
    AdminGuard.prototype.canActivate = function (route, state) {
        var currentUser = this.authenticationService.currentUser.value;
        console.log(currentUser);
        if (currentUser && (currentUser.userType == UserType.Admin || currentUser.userType == UserType.Bioengineer)) {
            // logged in so return true
            return true;
        }
        else if (currentUser) {
            // forbidden
            this.router.navigate(['/errors/error-403'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return this.authGuard.canActivate(route, state);
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.AuthGuard), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthenticationService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
