import { AfterViewInit, ChangeDetectorRef, ElementRef, QueryList, EventEmitter, } from '@angular/core';
import { CaseStatus } from 'app/core/models';
var ScenesTimeLineComponent = /** @class */ (function () {
    function ScenesTimeLineComponent(_cdr) {
        this._cdr = _cdr;
        this.prevLinkInactive = false;
        this.nextLinkInactive = true;
        this.loaded = false;
        this.selectedIndex = 0;
        this.eventsWrapperWidth = 0;
        this._viewInitialized = false;
        this._eventsMinDistance = 80;
        this._disabled = false;
        this._showContent = false;
        this.sceneSelected = new EventEmitter();
    }
    Object.defineProperty(ScenesTimeLineComponent.prototype, "plannerSceneElements", {
        get: function () {
            return this._plannerSceneElements;
        },
        set: function (value) {
            if (!value)
                return;
            this._plannerSceneElements = value.sort(function (a, b) { return a.creationDate.getTime() - b.creationDate.getTime(); });
            this.initView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScenesTimeLineComponent.prototype, "showContent", {
        get: function () {
            return this._showContent;
        },
        enumerable: true,
        configurable: true
    });
    ScenesTimeLineComponent.prototype.ngAfterViewInit = function () {
        this._cdr.detach();
        this._viewInitialized = true;
        this.initView();
    };
    ScenesTimeLineComponent.prototype.onScrollClick = function (event, forward) {
        event.preventDefault();
        this.updateSlide(this.eventsWrapperWidth, forward);
        this._cdr.detectChanges();
    };
    ScenesTimeLineComponent.prototype.onSceneClick = function (event, selectedItem) {
        event.preventDefault();
        if (this._disabled) {
            return;
        }
        var element = event.target;
        // detect click on the a single event - show new event content
        var visibleItem = this._plannerSceneElements[0];
        this._plannerSceneElements.forEach(function (item) {
            if (item.selected && item != selectedItem) {
                visibleItem = item;
                item.selected = false;
            }
        });
        this.selectedIndex = this._plannerSceneElements.indexOf(selectedItem);
        selectedItem.selected = true;
        this.updateFilling(element);
        this._cdr.detectChanges();
        this.sceneSelected.emit(selectedItem.id);
    };
    ScenesTimeLineComponent.prototype.getSceneStatusText = function (caseStatus) {
        return CaseStatus[caseStatus];
    };
    ScenesTimeLineComponent.prototype.initView = function () {
        if (!this._viewInitialized) {
            return;
        }
        this.translateTimeline(0, null);
        if (this._plannerSceneElements && this._plannerSceneElements.length) {
            this.selectedIndex = this._plannerSceneElements.length - 1;
            this._plannerSceneElements[this.selectedIndex].selected = true;
            this._cdr.detectChanges();
            this._timelineWrapperWidth = this.timeline.nativeElement.offsetWidth * 0.85;
            this.initTimeline(this._plannerSceneElements);
        }
        this._cdr.detectChanges();
    };
    ScenesTimeLineComponent.prototype.initTimeline = function (timeLines) {
        var eventsMinLapse = ScenesTimeLineComponent.minLapse(timeLines);
        // assign a left position to the single events along the timeline
        var timelineTotalDistance = this.setDatePosition(timeLines, this._eventsMinDistance, eventsMinLapse);
        // assign a width to the timeline
        this.setTimelineWidth(timeLines, this._eventsMinDistance, eventsMinLapse, timelineTotalDistance);
        // the timeline has been initialize - show it
        this.loaded = true;
    };
    ScenesTimeLineComponent.prototype.updateSlide = function (timelineTotWidth, forward) {
        var translateValue = ScenesTimeLineComponent.getTranslateValue(this.eventsWrapper.nativeElement);
        if (forward) {
            this.translateTimeline(translateValue - this._timelineWrapperWidth + this._eventsMinDistance, this._timelineWrapperWidth - timelineTotWidth);
        }
        else {
            this.translateTimeline(translateValue + this._timelineWrapperWidth - this._eventsMinDistance, null);
        }
    };
    ScenesTimeLineComponent.prototype.updateTimelinePosition = function (element) {
        var eventStyle = window.getComputedStyle(element);
        var eventLeft = ScenesTimeLineComponent.pxToNumber(eventStyle.getPropertyValue('left'));
        var translateValue = ScenesTimeLineComponent.getTranslateValue(this.eventsWrapper.nativeElement);
        if (eventLeft > this._timelineWrapperWidth - translateValue) {
            this.translateTimeline(-eventLeft + this._timelineWrapperWidth, this._timelineWrapperWidth - this.eventsWrapperWidth);
        }
    };
    ScenesTimeLineComponent.prototype.translateTimeline = function (value, totWidth) {
        // only negative translate value
        value = (value > 0) ? 0 : value;
        // do not translate more than timeline width
        value = (!(totWidth === null) && value < totWidth) ? totWidth : value;
        ScenesTimeLineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', value + 'px');
        // update navigation arrows visibility
        this.prevLinkInactive = value === 0;
        this.nextLinkInactive = value === totWidth;
    };
    ScenesTimeLineComponent.prototype.setTimelineWidth = function (elements, width, eventsMinLapse, timelineTotalDistance) {
        this.eventsWrapperWidth = Math.max(timelineTotalDistance * width, 1);
        var aHref = this.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
        this.updateFilling(aHref);
        this.updateTimelinePosition(aHref);
        return this.eventsWrapperWidth;
    };
    ScenesTimeLineComponent.prototype.updateFilling = function (element) {
        var eventStyle = window.getComputedStyle(element);
        var eventLeft = eventStyle.getPropertyValue('left');
        var eventWidth = eventStyle.getPropertyValue('width');
        var eventLeftNum = ScenesTimeLineComponent.pxToNumber(eventLeft) + ScenesTimeLineComponent.pxToNumber(eventWidth) / 2;
        var scaleValue = Math.max(eventLeftNum / this.eventsWrapperWidth, 70);
        ScenesTimeLineComponent.setTransformValue(this.fillingLine.nativeElement, 'scaleX', scaleValue);
    };
    ScenesTimeLineComponent.prototype.setDatePosition = function (elements, min, eventsMinLapse) {
        var timelineEventsArray = this.timelineEvents.toArray();
        var offsetDistance = 0;
        for (var i = 0; i < elements.length; i++) {
            var firstElementIndex = Math.max(i - 1, 0);
            var distanceNorm = ScenesTimeLineComponent.GetShrinkedNormalizedDistance(elements, firstElementIndex, i, eventsMinLapse);
            var distanceNormWithOffset = distanceNorm + offsetDistance;
            offsetDistance += distanceNorm;
            timelineEventsArray[i].nativeElement.style.left = distanceNormWithOffset * min + 'px';
            // span
            var span = timelineEventsArray[i].nativeElement.parentElement.children[1];
            var spanWidth = ScenesTimeLineComponent.getElementWidth(span);
            span.style.left = distanceNormWithOffset * min + spanWidth / 2 + 'px';
        }
        return offsetDistance;
    };
    ScenesTimeLineComponent.GetShrinkedNormalizedDistance = function (elements, firstElementIndex, i, eventsMinLapse) {
        var distance = ScenesTimeLineComponent.dayDiff(elements[firstElementIndex].creationDate, elements[i].creationDate);
        var shrinkedDistance = Math.round(Math.sqrt(distance) / 5) + eventsMinLapse;
        var distanceNorm = eventsMinLapse > 0 ? Math.round((shrinkedDistance) / eventsMinLapse) : 0;
        return distanceNorm;
    };
    ScenesTimeLineComponent.pxToNumber = function (val) {
        return Number(val.replace('px', ''));
    };
    ScenesTimeLineComponent.getElementWidth = function (element) {
        var computedStyle = window.getComputedStyle(element);
        if (!computedStyle.width) {
            return 0;
        }
        return ScenesTimeLineComponent.pxToNumber(computedStyle.width);
    };
    ScenesTimeLineComponent.getTranslateValue = function (timeline) {
        var timelineStyle = window.getComputedStyle(timeline);
        var timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
            timelineStyle.getPropertyValue('-moz-transform') ||
            timelineStyle.getPropertyValue('-ms-transform') ||
            timelineStyle.getPropertyValue('-o-transform') ||
            timelineStyle.getPropertyValue('transform');
        var translateValue = 0;
        if (timelineTranslate.indexOf('(') >= 0) {
            var timelineTranslateStr = timelineTranslate
                .split('(')[1]
                .split(')')[0]
                .split(',')[4];
            translateValue = Number(timelineTranslateStr);
        }
        return translateValue;
    };
    ScenesTimeLineComponent.setTransformValue = function (element, property, value) {
        element.style['-webkit-transform'] = property + '(' + value + ')';
        element.style['-moz-transform'] = property + '(' + value + ')';
        element.style['-ms-transform'] = property + '(' + value + ')';
        element.style['-o-transform'] = property + '(' + value + ')';
        element.style['transform'] = property + '(' + value + ')';
    };
    ScenesTimeLineComponent.dayDiff = function (first, second) {
        return Math.abs(Math.round(second.getTime() - first.getTime()));
    };
    ScenesTimeLineComponent.minLapse = function (elements) {
        if (elements && elements.length && elements.length === 1) {
            return 0;
        }
        var result = 0;
        for (var i = 1; i < elements.length; i++) {
            var distance = ScenesTimeLineComponent.dayDiff(elements[i - 1].creationDate, elements[i].creationDate);
            result = result ? Math.min(result, distance) : distance;
        }
        return result;
    };
    return ScenesTimeLineComponent;
}());
export { ScenesTimeLineComponent };
