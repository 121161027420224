import * as i0 from "@angular/core";
var FuseMatSidenavHelperService = /** @class */ (function () {
    /**
     * Constructor
     */
    function FuseMatSidenavHelperService() {
        this.sidenavInstances = [];
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set sidenav
     *
     * @param id
     * @param instance
     */
    FuseMatSidenavHelperService.prototype.setSidenav = function (id, instance) {
        this.sidenavInstances[id] = instance;
    };
    /**
     * Get sidenav
     *
     * @param id
     * @returns {any}
     */
    FuseMatSidenavHelperService.prototype.getSidenav = function (id) {
        return this.sidenavInstances[id];
    };
    FuseMatSidenavHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseMatSidenavHelperService_Factory() { return new FuseMatSidenavHelperService(); }, token: FuseMatSidenavHelperService, providedIn: "root" });
    return FuseMatSidenavHelperService;
}());
export { FuseMatSidenavHelperService };
