import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { PlannerCaseBundle } from '@appmodels';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
var ɵ0 = APP_DATE_FORMATS;
var EditBundleComponent = /** @class */ (function () {
    function EditBundleComponent(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        //this.visibilityGroups = this._data.visibilityGroups;
        this.sceneObjects = _.orderBy(this._data.sceneObjects, ['foundationDate', function (obj) { return obj.title.toLowerCase(); }], ['desc', 'asc']);
        this.bundle = this._data.bundle;
        this.bundleEditForm = this.createBundleEditForm(this.bundle); //this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(EditBundleComponent.prototype, "visabilityGroupsFormArray", {
        get: function () {
            return this.bundleEditForm.get('bundleEdit').controls;
        },
        enumerable: true,
        configurable: true
    });
    EditBundleComponent.prototype.getArrayGroup = function (control, name) {
        return control.get(name).controls;
    };
    EditBundleComponent.prototype.ngOnInit = function () {
    };
    EditBundleComponent.prototype.createBundleEditForm = function (bundleObj) {
        var form = new FormGroup({});
        var bundleFormArray = new FormArray([]);
        var bundle = null;
        if (bundleObj)
            bundle = JSON.parse(bundleObj.bundle);
        this.sceneObjects.forEach(function (sceneObject) {
            var selectedObjectInBundle = false;
            if (bundle) {
                if (bundle.some(function (e) { return e.objId === sceneObject.id; })) {
                    selectedObjectInBundle = true;
                }
            }
            var bundleFormGroup = new FormGroup({
                objId: new FormControl(sceneObject.id),
                objTitle: new FormControl({ value: sceneObject.title, disabled: true }, Validators.required),
                objCount: new FormControl(1, Validators.required),
                objSelected: new FormControl(selectedObjectInBundle, Validators.required),
            });
            bundleFormArray.push(bundleFormGroup);
        });
        form.addControl("bundleFormGroups", bundleFormArray);
        return form;
    };
    Object.defineProperty(EditBundleComponent.prototype, "bundleFormArray", {
        get: function () {
            return this.bundleEditForm.get('bundleFormGroups').controls;
        },
        enumerable: true,
        configurable: true
    });
    EditBundleComponent.prototype.saveBundle = function () {
        var formArray = this.bundleEditForm.controls['bundleFormGroups'];
        var bundle = [];
        formArray.controls.forEach(function (bundleFormGroup) {
            var objSelected = bundleFormGroup.get('objSelected').value;
            if (objSelected) {
                var objId = bundleFormGroup.get('objId').value;
                var objTitle = bundleFormGroup.get('objTitle').value;
                // const objCount = bundleFormGroup.get('id').value;
                bundle.push({ objId: objId, objTitle: objTitle });
            }
        });
        this.matDialogRef.close(['save', bundle]);
    };
    EditBundleComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return EditBundleComponent;
}());
export { EditBundleComponent };
export { ɵ0 };
