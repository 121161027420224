import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
//import { MailService } from '../../mail.service';
import { CaseEditDialogComponent } from '../../dialogs/case-edit/case-edit.component';
import { NGXLogger } from 'ngx-logger';
var CasesMainSidebarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MailService} _mailService
     * @param {MatDialog} _matDialog
     */
    function CasesMainSidebarComponent(
    // private _mailService: MailService,
    _matDialog, logger) {
        this._matDialog = _matDialog;
        this.logger = logger;
        this.folders = [
            {
                'id': 0,
                'handle': 'all',
                'title': 'All',
                'icon': 'inbox'
            },
            {
                'id': 1,
                'handle': 'own',
                'title': 'Own',
                'icon': 'inbox'
            },
            {
                'id': 2,
                'handle': 'sent',
                'title': 'Shared',
                'icon': 'send'
            },
            {
                'id': 3,
                'handle': 'drafts',
                'title': 'Demo',
                'icon': 'email_open'
            },
            {
                'id': 4,
                'handle': 'spam',
                'title': 'Archive',
                'icon': 'error'
            },
            {
                'id': 5,
                'handle': 'trash',
                'title': 'Trash',
                'icon': 'delete'
            }
        ];
        // Set the defaults
        this.accounts = {
            creapond: 'johndoe@creapond.com',
            withinpixels: 'johndoe@withinpixels.com'
        };
        this.selectedAccount = 'creapond';
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.onCaseCreate = new EventEmitter();
        this.onUsersSearch = new EventEmitter();
        this.users$ = new Subject();
    }
    Object.defineProperty(CasesMainSidebarComponent.prototype, "users", {
        //dialogRef: any;
        set: function (value) {
            this.users$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    CasesMainSidebarComponent.prototype.ngOnInit = function () {
        // this._mailService.onFoldersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(folders => {       
        //         this.folders = folders;
        //     });
        // this._mailService.onFiltersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(filters => {
        //         this.filters = filters;
        //     });
        // this._mailService.onLabelsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(labels => {
        //         this.labels = labels;
        //     });
        //     this._mailService.getFolders();
    };
    /**
     * On destroy
     */
    CasesMainSidebarComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Compose dialog
     */
    CasesMainSidebarComponent.prototype.caseEditDialog = function () {
        var _this = this;
        var dialogRef = this._matDialog.open(CaseEditDialogComponent, {
            panelClass: 'case-edit-dialog'
        });
        var instance = dialogRef.componentInstance;
        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (users) {
            instance.users = users;
        });
        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (query) {
            _this.onUsersSearch.emit(query);
        });
        dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var plannerCase = response[1];
            switch (actionType) {
                /**
                 * Create
                 */
                case 'create':
                    _this.logger.info('Case created, case :', plannerCase);
                    _this.onCaseCreate.emit(plannerCase);
                    break;
            }
        });
    };
    return CasesMainSidebarComponent;
}());
export { CasesMainSidebarComponent };
