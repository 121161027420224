import * as tslib_1 from "tslib";
export var ObjectOperationType;
(function (ObjectOperationType) {
    ObjectOperationType[ObjectOperationType["Add"] = 0] = "Add";
    ObjectOperationType[ObjectOperationType["Edit"] = 1] = "Edit";
    ObjectOperationType[ObjectOperationType["Delete"] = 2] = "Delete";
})(ObjectOperationType || (ObjectOperationType = {}));
var ObjectOperationArgs = /** @class */ (function () {
    function ObjectOperationArgs(obj, operationType) {
        this._obj = obj;
        this._operationType = operationType;
    }
    Object.defineProperty(ObjectOperationArgs.prototype, "obj", {
        get: function () {
            return this._obj;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObjectOperationArgs.prototype, "operationType", {
        get: function () {
            return this._operationType;
        },
        enumerable: true,
        configurable: true
    });
    return ObjectOperationArgs;
}());
export { ObjectOperationArgs };
var PlannerSceneObjectOperationArgs = /** @class */ (function (_super) {
    tslib_1.__extends(PlannerSceneObjectOperationArgs, _super);
    function PlannerSceneObjectOperationArgs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PlannerSceneObjectOperationArgs;
}(ObjectOperationArgs));
export { PlannerSceneObjectOperationArgs };
