import * as tslib_1 from "tslib";
import { Effect, ofType, Actions } from '@ngrx/effects';
import { GetCurrentUserSuccess, EUsersActions, GetUsers, GetUsersSuccess, EditUserSuccess, DeleteUserSuccess, GetOnlineUsersSuccess, ResetUsersFilterSuccess, SetUsersFilterSuccess, CreateCaseWebLinkSharesSuccess, RevokeCaseWebLinkSharesSuccess } from '../actions/users.actions';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { UsersService } from '@appservices';
import { EditUserCommand, DeleteUserCommand } from 'app/core/models/planner/commands/userCommand';
import { ObjectMapper, StringUtils } from 'app/core/helpers';
import { NewNotification } from '../actions/notifications.actions';
import { selectUsersFilters } from '../selectors/users.selectors';
import { VoidAction } from '../actions/app.actions';
var UsersEffects = /** @class */ (function () {
    function UsersEffects(_usersService, _actions$, _store) {
        var _this = this;
        this._usersService = _usersService;
        this._actions$ = _actions$;
        this._store = _store;
        this.getCurrentUser$ = this._actions$.pipe(ofType(EUsersActions.GetCurrentUser), switchMap(function () {
            return _this._usersService.getCurrentUser();
        }), switchMap(function (user) { return of(new GetCurrentUserSuccess(user)); }));
        this.setFilter$ = this._actions$.pipe(ofType(EUsersActions.SetUsersFilter), map(function (action) { return action.payload; }), switchMap(function (filterParams) {
            return [
                new SetUsersFilterSuccess(filterParams),
                new GetUsers(),
            ];
        }));
        this.resetFilters$ = this._actions$.pipe(ofType(EUsersActions.ResetUsersFilter), map(function (action) { return action.payload; }), switchMap(function (filterParams) {
            return [
                new ResetUsersFilterSuccess(filterParams || {}),
                (filterParams ? new GetUsers() : new VoidAction()),
            ];
        }));
        this.getUsers$ = this._actions$.pipe(ofType(EUsersActions.GetUsers), map(function (action) { return action; }), withLatestFrom(this._store.select(selectUsersFilters)), switchMap(function (_a) {
            var action = _a[0], usersFilters = _a[1];
            return _this._usersService.getUsers(usersFilters);
        }), switchMap(function (users) { return of(new GetUsersSuccess(users)); }));
        this.exportUsers$ = this._actions$.pipe(ofType(EUsersActions.ExportUsersList), map(function (action) { return action; }), withLatestFrom(this._store.select(selectUsersFilters)), switchMap(function (_a) {
            var action = _a[0], usersFilters = _a[1];
            return _this._usersService.exportUsersList(usersFilters);
        }), switchMap(function () { return of(); }));
        this.getOnlineUsers$ = this._actions$.pipe(ofType(EUsersActions.GetOnlineUsers), map(function (action) { return action; }), switchMap(function () {
            return _this._usersService.getOnlineUsers();
        }), switchMap(function (onlineUsers) { return of(new GetOnlineUsersSuccess(onlineUsers)); }));
        this.editUser$ = this._actions$.pipe(ofType(EUsersActions.EditUser), 
        //map(action => action.payload),
        switchMap(function (editUserRequest) {
            var editedUser = editUserRequest.editedUser;
            var userNewPassword = editUserRequest.newPassword;
            var editUserCommand = new EditUserCommand();
            ObjectMapper.mapObjectToObject(editedUser, editUserCommand);
            if (!StringUtils.isEmptyOrSpaces(userNewPassword))
                editUserCommand.newPassword = userNewPassword;
            return _this._usersService.editUser(editUserCommand).pipe(map(function (result) { return editedUser; }));
        }), switchMap(function (editedUser) {
            return [
                new EditUserSuccess(editedUser),
                new NewNotification({ type: 'UserSaved', message: 'User saved!', action: 'Ok' })
            ];
        }));
        this.deleteUser$ = this._actions$.pipe(ofType(EUsersActions.DeleteUser), map(function (action) { return action.payload; }), switchMap(function (deleteUserId) {
            var deleteUserCommand = new DeleteUserCommand();
            deleteUserCommand.userId = deleteUserId;
            return _this._usersService.deleteUser(deleteUserCommand).pipe(map(function (result) { return deleteUserId; }));
        }), switchMap(function (deleteUserId) {
            return [
                new DeleteUserSuccess(deleteUserId),
                new NewNotification({ type: 'UserDeleted', message: 'User deleted!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            new NewNotification({ type: 'UserDeleted', message: 'User delete error!', action: 'Error' });
            return throwError(err);
        }));
        this.createCaseWebLinkShares$ = this._actions$.pipe(ofType(EUsersActions.CreateCaseWebLinkShares), map(function (action) { return action.payload; }), 
        // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap(function (createWebLinkTokenCommand) {
            return _this._usersService.createWebLinkToken(createWebLinkTokenCommand);
        }), switchMap(function (webLinkToken) { return of(new CreateCaseWebLinkSharesSuccess(webLinkToken)); }));
        this.revokeCaseWebLinkShares$ = this._actions$.pipe(ofType(EUsersActions.RevokeCaseWebLinkShares), map(function (action) { return action.payload; }), 
        // withLatestFrom(this._store.select(selectUsersFilters)),
        switchMap(function (revokeWebLinkTokenCommand) {
            return _this._usersService.revokeWebLinkToken(revokeWebLinkTokenCommand).pipe(map(function (res) { return { caseId: revokeWebLinkTokenCommand.caseId, count: res }; }));
        }), switchMap(function (result) {
            return [
                new NewNotification({ type: 'RevokeCaseWebLinkShare', message: "Revoked " + result.count + " tokens!", action: 'Ok' }),
                new RevokeCaseWebLinkSharesSuccess(result.caseId)
            ];
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "getCurrentUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "setFilter$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "resetFilters$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "getUsers$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "exportUsers$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "getOnlineUsers$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "editUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "deleteUser$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "createCaseWebLinkShares$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], UsersEffects.prototype, "revokeCaseWebLinkShares$", void 0);
    return UsersEffects;
}());
export { UsersEffects };
