import { OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { NotificationArgs } from '@appmodels';
import { NotificationSnackbarComponent } from './snackbar/notification-snackbar.component';
var AppNotificationsComponent = /** @class */ (function () {
    function AppNotificationsComponent(_snackBar, router) {
        this._snackBar = _snackBar;
        this.router = router;
    }
    Object.defineProperty(AppNotificationsComponent.prototype, "notification", {
        set: function (notification) {
            var _this = this;
            if (!notification)
                return;
            var duration = 2500;
            var panelClass = ['blue-snackbar'];
            if (notification.type) {
                if (notification.type == 'NewMessage') {
                    duration = 0;
                    this.notifyBrowser("New message!", notification.message);
                }
                else if (notification.type == 'FileDelete') {
                    duration = 0;
                }
                else if (notification.type == 'CaseSceneSaved') {
                    duration = 0;
                }
            }
            var onAction = function () { };
            switch (notification.action) {
                case "Error": {
                    panelClass = ['red-snackbar'];
                    break;
                }
                case "Link": {
                    var caseId = notification.additionalParams.caseId;
                    var notificationLink_1 = '/chat/' + caseId;
                    onAction = function () {
                        _this.followLink(notificationLink_1);
                    };
                    break;
                }
            }
            var snackBarRef = this._snackBar.openFromComponent(NotificationSnackbarComponent, {
                duration: duration,
                panelClass: panelClass,
                horizontalPosition: 'right',
                data: {
                    message: notification.message,
                    action: notification.action,
                    onAction: onAction,
                    onBarClicked: function () {
                        snackBarRef.dismiss();
                    }
                }
            });
            // const snackBarRef = this._snackBar.open(notification.message, notification.action, {
            //   duration: duration,
            //   panelClass: panelClass,
            //   horizontalPosition: 'right'
            // });
            //   snackBarRef.onAction().subscribe(() => {      
            //     onAction();
            //   });
        },
        enumerable: true,
        configurable: true
    });
    // onAction()
    // {
    // }
    AppNotificationsComponent.prototype.ngOnInit = function () {
    };
    AppNotificationsComponent.prototype.followLink = function (link) {
        this.router.navigateByUrl(link);
    };
    AppNotificationsComponent.prototype.notifyBrowser = function (subject, text) {
        // Проверка поддержки браузером уведомлений
        if (!("Notification" in window)) {
            return;
        }
        // Проверка разрешения на отправку уведомлений
        else if (Notification.permission === "granted") {
            // Если разрешено, то создаём уведомление
            this.showBrowserNotification(subject, text);
        }
        // В противном случае, запрашиваем разрешение
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // Если пользователь разрешил, то создаём уведомление
                if (permission === "granted") {
                    this.showBrowserNotification(subject, text);
                }
            });
        }
    };
    AppNotificationsComponent.prototype.showBrowserNotification = function (subject, text) {
        var options = {
            silent: true,
            body: text,
        };
        var notification = new Notification(subject, options);
        notification.onclick = function (x) { window.focus(); this.close(); };
        try {
            var audio = new Audio("/assets/sounds/message-sound.mp3");
            audio.volume = 0.2;
            audio.play();
        }
        catch (_a) {
        }
    };
    return AppNotificationsComponent;
}());
export { AppNotificationsComponent };
