import * as tslib_1 from "tslib";
import { Effect, ofType, Actions } from '@ngrx/effects';
//import { SIGNALR_HUB_UNSTARTED, mergeMapHubToAction, startSignalRHub, SIGNALR_CONNECTED, createSignalRHub, stopSignalRHub, selectHubsStatuses, SignalRHub, signalrHubUnstarted, SignalRHubState, SIGNALR_DISCONNECTED } from 'ngrx-signalr-core';
import { tap, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { VoidAction } from '../actions/app.actions';
import { selectCurrentUser } from '../selectors/users.selectors';
import { EAppSignalRActions } from '../actions/appsignalr.actions';
import { AppConfig } from 'app/app-config.module';
import { AuthenticationService } from '@appservices';
import { NewMessageFromServer } from '../actions/messages.actions';
import * as signalr from '@microsoft/signalr';
import { ObjectDateFieldsFormatter } from 'app/core/helpers';
import { CaseSceneSavedFromServer } from '../actions/cases.actions';
import { from } from 'rxjs';
var AppSignalREffects = /** @class */ (function () {
    function AppSignalREffects(config, _authenticationService, logger, _actions$, _store) {
        var _this = this;
        this.config = config;
        this._authenticationService = _authenticationService;
        this.logger = logger;
        this._actions$ = _actions$;
        this._store = _store;
        this.signalRCreateHub$ = this._actions$.pipe(ofType(EAppSignalRActions.CreateHub), tap(function (x) { return _this.logger.log('SIGNALR_HUB_CREATING'); }), switchMap(function (_) {
            return _this._store.select(selectCurrentUser);
        }), switchMap(function (user) {
            //TODO: check valid token
            if (user != null) {
                var options = {
                    accessTokenFactory: function () {
                        return user.token;
                    }
                };
                _this._hubConnection = new signalr.HubConnectionBuilder()
                    .withUrl(_this.config.signalREndpoint, options)
                    .withAutomaticReconnect()
                    .configureLogging(_this.logger)
                    .build();
                _this._hubConnection.on("OnServerNewMessage", function (data) {
                    ObjectDateFieldsFormatter.convertFieldsDate(data);
                    _this.logger.log('SignalR', 'OnServerNewMessage', data);
                    _this._store.dispatch(new NewMessageFromServer(data));
                });
                _this._hubConnection.on("OnServerCaseSceneSaved", function (data) {
                    ObjectDateFieldsFormatter.convertFieldsDate(data);
                    _this.logger.log('SignalR', 'OnServerCaseSceneSaved', data);
                    _this._store.dispatch(new CaseSceneSavedFromServer(data));
                });
                return from(_this._hubConnection.start()).pipe(tap(function (_) { return _this.logger.log("SIGNALR_CONNECTED"); }), map(function (_) { return new VoidAction(); }));
            }
            return [new VoidAction()];
        }));
        this.signalRDisconnect$ = this._actions$.pipe(ofType(EAppSignalRActions.DisconnectHub), tap(function (x) { return _this.logger.log('SIGNALR_HUB_DISCONNECTING'); }), switchMap(function () {
            return from(_this._hubConnection.stop()).pipe(tap(function (_) { return _this.logger.log("SIGNALR_DISCONNECTED"); }), map(function (_) { return new VoidAction(); }));
        }));
        //    this._customState = new Subject<boolean>();
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppSignalREffects.prototype, "signalRCreateHub$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppSignalREffects.prototype, "signalRDisconnect$", void 0);
    return AppSignalREffects;
}());
export { AppSignalREffects };
