import * as tslib_1 from "tslib";
import { GetAppLogsGroups, EAppLogsActions, GetAppLogsGroupsSuccess, GetAppLogsByConnectionSuccess, SetAppLogsFilterParamsSuccess, SetAppLogsSearchPatternSuccess } from '../actions/appLogs.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppLogsService } from 'app/core/services/planner/appLogs.service';
import { NGXLogger } from 'ngx-logger';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { selectAppLogsFilterParams, selectAppLogsGroups, selectAppLogsSearchPattern } from '../selectors/appLogs.selector';
var AppLogsEffects = /** @class */ (function () {
    function AppLogsEffects(_appLogsService, _actions$, _store, _logger) {
        var _this = this;
        this._appLogsService = _appLogsService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.setFilterParams$ = this._actions$.pipe(ofType(EAppLogsActions.SetAppLogsFilterParams), map(function (action) { return action.isWithAdmins; }), switchMap(function (filterParams) {
            return [
                new SetAppLogsFilterParamsSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        }));
        this.setSearchPattern$ = this._actions$.pipe(ofType(EAppLogsActions.SetAppLogsSearchPattern), map(function (action) { return action.payload; }), switchMap(function (filterParams) {
            return [
                new SetAppLogsSearchPatternSuccess(filterParams),
                new GetAppLogsGroups(true),
            ];
        }));
        this.getAppLogsGroups$ = this._actions$.pipe(ofType(EAppLogsActions.GetAppLogsGroups), map(function (action) { return action.isReload; }), 
        //map
        switchMap(function (isReload) {
            _this._logger.info("GetAppLogsGroups, is reload: " + isReload);
            if (isReload) {
                return of([]);
            }
            else {
                return _this._store.pipe(select(selectAppLogsGroups), take(1));
            }
        }), withLatestFrom(this._store.select(selectAppLogsFilterParams), this._store.select(selectAppLogsSearchPattern)), switchMap(function (_a) {
            var existedAppLogsGroups = _a[0], appLogsFilterParams = _a[1], appLogsSearchPattern = _a[2];
            var skip = existedAppLogsGroups ? existedAppLogsGroups.length : 0;
            var take = 20;
            return _this._appLogsService.getAppLogsGroups(appLogsFilterParams.isWithAdmins, appLogsSearchPattern, 'LastActivity', SortOrder.Descending, skip, take);
        }), switchMap(function (appLogsGroups) { return of(new GetAppLogsGroupsSuccess(appLogsGroups)); }));
        this.getAppLogsByConnection$ = this._actions$.pipe(ofType(EAppLogsActions.GetAppLogsByConnection), map(function (action) { return action.payload; }), //map
        switchMap(function (connectionId) {
            return _this._appLogsService.getAppLogsByConnection(connectionId);
        }), switchMap(function (appLogs) { return of(new GetAppLogsByConnectionSuccess(appLogs)); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppLogsEffects.prototype, "setFilterParams$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppLogsEffects.prototype, "setSearchPattern$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppLogsEffects.prototype, "getAppLogsGroups$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppLogsEffects.prototype, "getAppLogsByConnection$", void 0);
    return AppLogsEffects;
}());
export { AppLogsEffects };
