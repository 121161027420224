import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { selectAppLogsGroups } from '../store/selectors/appLogs.selector';
import { SetAppLogsFilterParams } from '../store/actions/appLogs.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
var AppLogsResolver = /** @class */ (function () {
    function AppLogsResolver(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    AppLogsResolver.prototype.resolve = function (route, state) {
        var routeParams = route.params;
        this.logger.info('App logs route resolver: ', route.params);
        var obsCollection = [];
        obsCollection.push(this._store.select(selectAppLogsGroups).pipe(filter(function (appLogsGroups) { return appLogsGroups != null; }), take(1)));
        obsCollection.push(of(this._store.dispatch(new SetAppLogsFilterParams(false))));
        return forkJoin(obsCollection);
    };
    AppLogsResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppLogsResolver_Factory() { return new AppLogsResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: AppLogsResolver, providedIn: "root" });
    return AppLogsResolver;
}());
export { AppLogsResolver };
