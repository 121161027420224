import * as tslib_1 from "tslib";
import { AuthenticationService, UsersService } from '@appservices';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EAuthActions, SendRecoverPasswordRequestSuccess, RecoverPasswordSuccess, SetManagementOrganizationIdSuccess } from '../actions/auth.actions';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
//import { Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(_authService, _usersService, _actions$, _store) {
        var _this = this;
        this._authService = _authService;
        this._usersService = _usersService;
        this._actions$ = _actions$;
        this._store = _store;
        this.setManagementOrganizationByName$ = this._actions$.pipe(ofType(EAuthActions.SetManagementOrganizationByName), map(function (action) { return action.payload; }), switchMap(function (organizationTitle) {
            return _this._usersService.getPlannerUsersOrganization(organizationTitle);
        }), switchMap(function (organization) { return of(new SetManagementOrganizationIdSuccess(organization.id)); }));
        this.sendRecoverPasswordRequest$ = this._actions$.pipe(ofType(EAuthActions.SendRecoverPasswordRequest), map(function (action) { return action.payload; }), switchMap(function (userEmail) {
            var origin = window.location.origin;
            var requestConfirmUrl = origin + "/resetpassword";
            console.log(requestConfirmUrl);
            return _this._authService.sendRecoverPasswordRequest(userEmail, requestConfirmUrl);
        }), switchMap(function (user) { return of(new SendRecoverPasswordRequestSuccess(user)); }));
        this.recoverPassword$ = this._actions$.pipe(ofType(EAuthActions.RecoverPassword), map(function (action) { return action.payload; }), switchMap(function (recoverPasswordCommand) {
            return _this._authService.recoverPassword(recoverPasswordCommand);
        }), switchMap(function (result) { return of(new RecoverPasswordSuccess(result)); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "setManagementOrganizationByName$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "sendRecoverPasswordRequest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "recoverPassword$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
