import { OnInit, Renderer2, ElementRef } from '@angular/core';
import { CaseStatus } from 'app/core/models';
var CaseStatusStyleDirective = /** @class */ (function () {
    function CaseStatusStyleDirective(renderer, hostElement) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this._elementClass = [];
        this.caseStatusPrefix = '';
    }
    Object.defineProperty(CaseStatusStyleDirective.prototype, "caseStatusIntToStr", {
        get: function () {
            if (!CaseStatusStyleDirective._caseStatusEnum) {
                CaseStatusStyleDirective._caseStatusEnum = CaseStatusStyleDirective.GetCaseStatusIntToStr();
            }
            return CaseStatusStyleDirective._caseStatusEnum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CaseStatusStyleDirective.prototype, "caseStatusStyle", {
        set: function (status) {
            this.caseStatus = status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CaseStatusStyleDirective.prototype, "caseStatusStylePrefix", {
        set: function (prefix) {
            this.caseStatusPrefix = prefix;
        },
        enumerable: true,
        configurable: true
    });
    CaseStatusStyleDirective.prototype.ngOnInit = function () {
        var prop = this.caseStatusIntToStr[this.caseStatus]; //props.filter(prop => CaseStatus[prop] == this.caseStatus);
        var caseStatusStyleClass = this.caseStatusPrefix + 'case-status-style-' + prop;
        this.renderer.addClass(this.hostElement.nativeElement, caseStatusStyleClass);
        //  this._elementClass.push(caseStatusStyleClass);
    };
    CaseStatusStyleDirective.GetCaseStatusIntToStr = function () {
        var properties = new Array();
        for (var property in CaseStatus) {
            properties.push(property);
        }
        var enumObj = new Object();
        var enumValsCount = properties.length / 2;
        for (var i = 0; i < enumValsCount; i++) {
            enumObj[properties[i]] = properties[enumValsCount + i];
        }
        return enumObj;
    };
    return CaseStatusStyleDirective;
}());
export { CaseStatusStyleDirective };
