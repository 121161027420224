import { ElementRef, OnInit } from '@angular/core';
var BGColorDirective = /** @class */ (function () {
    function BGColorDirective(el) {
        this._elementRef = el;
    }
    Object.defineProperty(BGColorDirective.prototype, "setBGColor", {
        set: function (bgColor) {
            this._bgColor = bgColor;
        },
        enumerable: true,
        configurable: true
    });
    BGColorDirective.prototype.ngOnInit = function () {
        this._elementRef.nativeElement.style.backgroundColor = this._bgColor;
    };
    return BGColorDirective;
}());
export { BGColorDirective };
