import { STLReader } from "../geometry/stl-reader";
import { PLYReader } from "../geometry/ply-reader";
import { Subject } from "rxjs";
import { MeshWeld } from "../geometry/mesh-weld";
import * as i0 from "@angular/core";
var GeometryService = /** @class */ (function () {
    function GeometryService() {
    }
    GeometryService.prototype.convertToPLY = function (file) {
        var obs = new Subject();
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            var arrayBuffer = event.target['result'];
            var stlReader = new STLReader();
            var plyReader = new PLYReader();
            var meshWeld = new MeshWeld();
            var mesh = stlReader.Read(arrayBuffer);
            var weldedMesh = meshWeld.FullWeld(mesh);
            // console.log(mesh);
            // console.log(weldedMesh);
            var fileBytes = plyReader.Write(weldedMesh);
            var convertedFile = new File([new Blob([fileBytes])], file.name + '.ply');
            obs.next(convertedFile);
            obs.complete();
        };
        fileReader.readAsArrayBuffer(file);
        return obs;
    };
    GeometryService.prototype.convertToSTL = function (file) {
        var fileReader = new FileReader();
        var obs = new Subject();
        fileReader.onload = function (event) {
            var arrayBuffer = event.target['result'];
            var stlReader = new STLReader();
            var plyReader = new PLYReader();
            var mesh = plyReader.Read(arrayBuffer);
            var fileBytes = stlReader.Write(mesh);
            var newFileName = file.name + '.stl';
            var stlExtLastIdx = file.name.lastIndexOf('.stl');
            if (stlExtLastIdx == file.name.length - (4 + 4)) {
                newFileName = file.name.slice(0, stlExtLastIdx) + '.stl';
            }
            var convertedFile = new File([new Blob([fileBytes])], newFileName);
            obs.next(convertedFile);
            obs.complete();
        };
        fileReader.readAsArrayBuffer(file);
        return obs;
    };
    GeometryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeometryService_Factory() { return new GeometryService(); }, token: GeometryService, providedIn: "root" });
    return GeometryService;
}());
export { GeometryService };
