import * as tslib_1 from "tslib";
var UserCommand = /** @class */ (function () {
    function UserCommand() {
        this.firstName = null;
        this.lastName = null;
        this.middleName = null;
        this.medicalReferalsType = null;
        this.speciality = null;
        this.phoneNumber = null;
        this.country = null;
        this.city = null;
        this.company = null;
        this.language = null;
    }
    return UserCommand;
}());
export { UserCommand };
var RegisterUserCommand = /** @class */ (function (_super) {
    tslib_1.__extends(RegisterUserCommand, _super);
    function RegisterUserCommand() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.email = null;
        _this.password = null;
        _this.referalType = null;
        _this.referalDetails = null;
        return _this;
    }
    return RegisterUserCommand;
}(UserCommand));
export { RegisterUserCommand };
var EditUserCommand = /** @class */ (function (_super) {
    tslib_1.__extends(EditUserCommand, _super);
    function EditUserCommand() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.id = null;
        _this.newPassword = null;
        _this.roles = [];
        return _this;
    }
    return EditUserCommand;
}(UserCommand));
export { EditUserCommand };
var DeleteUserCommand = /** @class */ (function () {
    function DeleteUserCommand() {
        this.userId = null;
    }
    return DeleteUserCommand;
}());
export { DeleteUserCommand };
