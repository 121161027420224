import { AppConfig } from 'app/app-config.module';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { map, switchMap, mergeMap, catchError, finalize, tap } from 'rxjs/operators';
import { GetPlannerFilesSASListQuery, GetPlannerFileSASQuery, RequestPlannerFileUploadCommand, ApiResponse } from 'app/core/models';
import * as FileSaver from 'file-saver';
import { Observable, merge, concat, Subscriber, from, of, } from 'rxjs';
import { FileResponse } from 'app/core/models/';
import { Md5 } from 'ts-md5/dist/md5';
import { ObjectMapper } from 'app/core/helpers';
import * as JSZip from 'jszip';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '@appservices';
import { ConvertType } from 'app/core/models/planner/enums/fileEnums';
import { GeometryService } from './geometry.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "../storage/storage.service";
import * as i4 from "ngx-logger";
//import { BlobServiceClient } from '@azure/storage-blob';
var FilesService = /** @class */ (function () {
    function FilesService(config, http, _storageService, logger) {
        this.config = config;
        this.http = http;
        this._storageService = _storageService;
        this.logger = logger;
    }
    FilesService.prototype.getFileDownloadUrl = function (caseId, fileId, fileName) {
        this.logger.info('Get file download url.', "CaseId: " + caseId + ",", "fileId: " + fileId + ",", "fileName: " + fileName);
        var filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        var fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);
        return this.getFilesSaS(filesSASQuery).pipe(map(function (response) {
            return response.sasResponseFiles[0].url;
        }));
    };
    FilesService.prototype.downloadFile = function (caseId, fileId, fileName, convertType) {
        if (convertType === void 0) { convertType = ConvertType.None; }
        this.logger.info('Download file.', "CaseId: " + caseId + ",", "fileId: " + fileId + ",", "fileName: " + fileName);
        var filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        var fileSASQuery = new GetPlannerFileSASQuery();
        fileSASQuery.FileId = fileId;
        fileSASQuery.FileName = fileName;
        filesSASQuery.SASRequestFiles.push(fileSASQuery);
        return this.download(filesSASQuery, convertType);
    };
    FilesService.prototype.downloadFiles = function (caseId, filesQuery) {
        this.logger.info('Download files.', "CaseId: " + caseId + ",", filesQuery);
        var filesSASQuery = new GetPlannerFilesSASListQuery();
        filesSASQuery.CaseId = caseId;
        filesSASQuery.SASRequestFiles = filesQuery;
        return this.download(filesSASQuery);
    };
    FilesService.prototype.uploadFile = function (file, caseId, title, fileType, additionalInfo, isNeedToZip) {
        var _this = this;
        this.logger.info('Upload file.', "CaseId: " + caseId + ",", "title: " + title + ",", "fileType: " + fileType, "additionalInfo: " + additionalInfo, "isNeedToZip: " + isNeedToZip);
        var sasRequest = new RequestPlannerFileUploadCommand();
        sasRequest.caseId = caseId;
        sasRequest.fileType = fileType;
        sasRequest.additionalInfo = encodeURIComponent(additionalInfo);
        sasRequest.fileName = file.name + (isNeedToZip ? '.zip' : '');
        sasRequest.title = title;
        sasRequest.isFileZipped = isNeedToZip;
        sasRequest.fileSize = file.size;
        var fileResponse = new FileResponse();
        var observable = new Observable(function (subscriber) {
            fileResponse.status = 'md5 calc';
            subscriber.next(fileResponse);
            _this.getMd5(file).pipe(switchMap(function (md5string) {
                fileResponse.status = 'zipping';
                subscriber.next(fileResponse);
                var fileProcessing = isNeedToZip ? _this.zipFile(file) : of(file);
                return fileProcessing.pipe(map(function (processedFile) {
                    if (isNeedToZip)
                        sasRequest.fileSizeZipped = processedFile.size;
                    return processedFile;
                }), switchMap(function (blob) {
                    _this.logger.info(md5string);
                    sasRequest.md5hash = md5string;
                    var urlParams = ObjectMapper.mapObjectToURLParams(sasRequest);
                    fileResponse.status = 'getting sas';
                    subscriber.next(fileResponse);
                    var url = _this.config.apiEndpoint + "/api/public/Files/RequestFileUpload?" + urlParams;
                    _this.logger.debug('HTTP GET: request file upload', sasRequest, "url: " + url);
                    return _this.http.get(url).pipe(tap(function (x) { return _this.logger.debug('Request file upload response:', x); }), switchMap(function (res) {
                        _this.logger.debug('Sas response:', res);
                        // const fileBlobNameUrlEncoded = encodeURIComponent(res.fileBlobName);
                        fileResponse.fileName = title;
                        fileResponse.fileId = res.fileId;
                        fileResponse.status = 'uploading';
                        fileResponse.progress = 0;
                        subscriber.next(fileResponse);
                        _this.logger.debug('BLOB PUT: upload to blob storage', blob);
                        //  return res;
                        var uploadObs = _this._storageService.uploadToBlobStorage(blob, res.url, res.fileBlobName).pipe(finalize(function () { return _this.logger.debug('Upload to blob storage done'); }), map(function (x) {
                            fileResponse.status = 'uploading';
                            fileResponse.progress = Math.ceil((x / blob.size) * 100);
                            subscriber.next(fileResponse);
                            return x;
                        }));
                        var commitObs = _this.http.get(_this.config.apiEndpoint + "/api/public/Files/SetFileUploaded?caseId=" + caseId + '&fileId=' + res.fileId).pipe(tap(function (commitResponse) { return _this.logger.debug('Set file uploaded done.', "CaseId: " + caseId + ",", "fileId: " + res.fileId, commitResponse); }), map(function (x) {
                            fileResponse.status = 'done';
                            subscriber.next(fileResponse);
                        }), finalize(function () {
                            subscriber.complete();
                        }));
                        return concat(uploadObs, commitObs);
                    }));
                }));
            })).pipe(catchError(function (err) {
                subscriber.error(err);
                throw err;
            }))
                .subscribe();
        });
        return observable;
    };
    FilesService.prototype.editFile = function (deletePlannerFileCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/FilesManagement/EditPlannerFile";
        this.logger.debug("HTTP POST edit planner file.", "File to edit: " + deletePlannerFileCommand + ",", "url: " + url);
        return this.http.post(url, deletePlannerFileCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response edit file:", response);
        }), map(function (response) { return new ApiResponse(response); }), catchError(function (error) {
            // console.error(error)
            _this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    };
    FilesService.prototype.deleteFile = function (deletePlannerFileCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/FilesManagement/DeletePlannerFile";
        this.logger.debug("HTTP POST delete planner file.", "File to delete: " + deletePlannerFileCommand + ",", "url: " + url);
        return this.http.post(url, deletePlannerFileCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response delete file:", response);
        }), map(function (response) { return new ApiResponse(response); }), catchError(function (error) {
            // console.error(error)
            _this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    };
    FilesService.prototype.editPlannerFilePermissions = function (editPlannerFilePermissionsCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/FilesManagement/EditPlannerFilePermissions";
        this.logger.debug("HTTP POST edit planner files permissions.", "Files to edit permissions: " + editPlannerFilePermissionsCommand + ",", "url: " + url);
        return this.http.post(url, editPlannerFilePermissionsCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response edit planner files permissions:", response);
        }), map(function (response) { return new ApiResponse(response); }), catchError(function (error) {
            // console.error(error)
            _this.logger.error(error);
            return of(new ApiResponse(null, false, error.error));
        }));
    };
    FilesService.prototype.download = function (filesSASQuery, convertType) {
        var _this = this;
        if (convertType === void 0) { convertType = ConvertType.None; }
        return this.getFilesSaS(filesSASQuery)
            .pipe(mergeMap(function (response) {
            _this.logger.debug('Fetch files SAS response:', response);
            var obsCollection = new Array();
            var fileResponses = new Array();
            response.sasResponseFiles.forEach(function (sasResponseFile) {
                var request = new HttpRequest('GET', sasResponseFile.url, {
                    reportProgress: true,
                    responseType: 'blob'
                });
                var fileResponse = new FileResponse();
                fileResponse.fileId = sasResponseFile.fileId;
                fileResponse.fileName = sasResponseFile.fileName;
                fileResponses.push(fileResponse);
                var obs = _this.http.request(request).pipe(map(function (event) {
                    // progress
                    if (event.type === HttpEventType.DownloadProgress) {
                        var percentage = 100 / event.total * event.loaded;
                        fileResponse.progress = percentage;
                        return fileResponses;
                    }
                    else {
                        //  this.logger.info(event);
                    }
                    // finished
                    if (event.type === HttpEventType.Response) {
                        //this.logger.info(event.body);
                        _this.saveFile(event.body, sasResponseFile.fileName, convertType);
                        return null;
                    }
                    return null;
                }));
                obsCollection.push(obs);
            });
            var mergedObs = obsCollection[0];
            for (var i = 1; i < obsCollection.length; i++) {
                mergedObs = merge(mergedObs, obsCollection[i]);
            }
            return mergedObs;
        }));
    };
    FilesService.prototype.getFilesSaS = function (filesSASQuery) {
        var url = this.config.apiEndpoint + "/api/public/Files/FetchFilesSAS";
        this.logger.debug('HTTP POST: fetch files SAS', filesSASQuery, "url: " + url);
        return this.http.post(url, filesSASQuery);
    };
    FilesService.prototype.saveFile = function (blob, fileName, convertType) {
        var _this = this;
        if (convertType === void 0) { convertType = ConvertType.None; }
        var convertArgs = {};
        convertArgs.blob = blob;
        convertArgs.fileName = fileName;
        var convert = of(convertArgs);
        //convertType = ConvertType.PlyToStl;
        switch (convertType) {
            case ConvertType.PlyToStl:
                var file = new File([new Blob([blob])], fileName);
                convert = this.unzipFile(file).pipe(switchMap(function (unzipResult) {
                    var blob = unzipResult.blob;
                    var fileName = unzipResult.filename;
                    return new GeometryService().convertToSTL(new File([new Blob([blob])], fileName)).pipe(map(function (convertedFile) {
                        var convertResult = {};
                        convertResult.blob = convertedFile;
                        convertResult.fileName = convertedFile.name;
                        return convertResult;
                    }));
                }));
        }
        convert.subscribe(function (converResult) {
            FileSaver.saveAs(converResult.blob, converResult.fileName);
            _this.logger.info('File saved');
            _this.logger.debug('File info:', converResult.blob, "fileName: " + converResult.fileName);
        });
    };
    FilesService.prototype.getMd5 = function (file) {
        var _this = this;
        this.logger.debug('Getting file md5', file);
        var md5 = new Md5();
        var reader = new FileReader();
        var observable = Observable.create(function (observer) {
            // if success
            reader.onloadend = (function (ev) {
                var arrayBuffer = reader.result;
                var bytes = new Uint8Array(arrayBuffer);
                // resolve(bytes);
                md5.appendByteArray(bytes);
                var md5String = md5.end();
                _this.logger.info('Getting file md5 done!', "md5String: " + md5String);
                observer.next(md5String);
                observer.complete();
            });
            // if failed
            reader.onerror = function (error) {
                _this.logger.error(error);
                observer.error(error);
            };
        });
        reader.readAsArrayBuffer(file);
        return observable;
    };
    FilesService.prototype.zipFile = function (file) {
        var _this = this;
        this.logger.debug('Zipping file', file);
        var zip = new JSZip();
        zip.file(file.name, file);
        var obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(tap(function (result) {
            _this.logger.info('Zipping file done!');
            _this.logger.debug('Zipped file:', result);
        }));
        return obs;
    };
    FilesService.prototype.unzipFile = function (file) {
        this.logger.debug('Unzipping file', file);
        var zip = new JSZip();
        var obs = from(zip.loadAsync(file)).pipe(switchMap(function (contents) {
            var filename = Object.keys(contents["files"])[0];
            return from(zip.file(filename).async('uint8array')).pipe(map(function (content) { return [filename, content]; }));
        }), map((function (_a) {
            var filename = _a[0], content = _a[1];
            var result = {};
            result.blob = new Blob([content]);
            result.filename = filename;
            return result;
        })));
        // let obs = from(zip.loadAsync(file).then(function (contents) {
        //     Object.keys(contents.files).forEach(function (filename) {
        //         zip.file(filename).async('nodebuffer').then(function (content) {
        //             console.log(filename);
        //             console.log(content);
        //         });
        //     });
        // }));
        return obs;
        // let zip = new JSZip();
        // zip.file(file.name, file)
        // let obs = from(zip.generateAsync({ type: "blob", compression: 'DEFLATE', compressionOptions: { level: 6 } })).pipe(
        //     tap(result => {
        //         this.logger.info('Zipping file done!');
        //         this.logger.debug('Zipped file:', result);
        //     })
        // );
        // return obs as Observable<Blob>;
    };
    FilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.StorageService), i0.ɵɵinject(i4.NGXLogger)); }, token: FilesService, providedIn: "root" });
    return FilesService;
}());
export { FilesService };
