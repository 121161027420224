import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { MedicalReferals, Language, MarketingReferalType, Speciality } from '@appmodels';
import { LanguageService, UsersService } from '@appservices';
import { MatStepper } from '@angular/material';
import { RegisterUserCommand } from 'app/core/models/planner/commands/userCommand';
import { ObjectMapper, confirmPasswordValidator, passwordPattern } from 'app/core/helpers';
import { NGXLogger } from 'ngx-logger';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as en } from './i18n/en';
import { locale as ru } from './i18n/ru';
import { Store } from '@ngrx/store';
import { selectManagementOrganizationId } from 'app/core/store/selectors/auth.selector';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(_fuseConfigService, _formBuilder, _usersService, _logger, _fuseTranslationLoaderService, _languageService, _ref, _store) {
        this._fuseConfigService = _fuseConfigService;
        this._formBuilder = _formBuilder;
        this._usersService = _usersService;
        this._logger = _logger;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._languageService = _languageService;
        this._ref = _ref;
        this._store = _store;
        this.medicalReferalsEnum = MedicalReferals;
        this.specialityEnum = Speciality;
        this.marketingReferalTypeEnum = MarketingReferalType;
        this.languageEnum = Language;
        this.registeringInProgress = false;
        this.isRegistrationSuccess = false;
        this.validateSpeciality = function (control) {
            if (!control || !control.parent) {
                return null;
            }
            var medicalReferals = control.parent.get('medicalReferalsType').value;
            // console.log(control);
            var speciality = control.value;
            var isValid = false;
            // console.log(medicalReferals);
            if (medicalReferals == MedicalReferals.Human) {
                if (speciality || speciality != Speciality.NotSet) {
                    isValid = true;
                }
                else {
                    isValid = false;
                }
            }
            else {
                isValid = true;
            }
            //console.log(isValid);
            return isValid ? null : { wrongSpeciality: true };
        };
        this._fuseTranslationLoaderService.loadTranslations(en, ru);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // const lang = navigator.language || window.navigator.language;
        // _translateService.use('ru');
        //console.log(lang);
    }
    Object.defineProperty(RegisterComponent.prototype, "selectedLanguage", {
        get: function () {
            return this._languageService.selectedLang;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "supportedLanguages", {
        get: function () {
            return this._languageService.supportedLangs;
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.firstStepFormGroup = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern("notAvailable")]],
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: [''],
            medicalReferalsType: ['', Validators.required],
            speciality: [this.specialityEnum.NotSet, this.validateSpeciality],
            password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        this.secondStepFormGroup = this._formBuilder.group({
            country: ['', Validators.required],
            city: ['', [Validators.required]],
            phoneNumber: ['', Validators.required],
            company: ['', Validators.required],
            referalType: [''],
            terms: ['', [Validators.required, Validators.pattern('true')]],
            mailing: ['', [Validators.required, Validators.pattern('true')]],
            language: ['', Validators.required]
        });
        this.firstStepFormGroup.get('medicalReferalsType').valueChanges.subscribe(function (value) {
            if (value == MedicalReferals.Veterinary)
                _this.firstStepFormGroup.get('speciality').setValue(Speciality.NotSet);
            _this.firstStepFormGroup.get('speciality').updateValueAndValidity();
        });
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.firstStepFormGroup.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.firstStepFormGroup.get('passwordConfirm').updateValueAndValidity();
        });
        this.setFormLanguage();
        //   this.registerStepper.selectedIndex = 2;
        this._store.select(selectManagementOrganizationId).pipe(takeUntil(this._unsubscribeAll)).subscribe(function (organizationId) {
            _this._managementOrganizationId = organizationId;
        });
    };
    RegisterComponent.prototype.setLanguage = function (lang) {
        // this.removeFormLanguage();
        this._languageService.setLanguage(lang);
        this.setFormLanguage();
    };
    RegisterComponent.prototype.formLanguageChanged = function (event) {
        var formLang = event.value;
        if (formLang == Language.ruRU) {
            this.setLanguage('ru');
        }
        else {
            this.setLanguage('en');
        }
    };
    // private removeFormLanguage() {
    //     this.secondStepFormGroup.controls['language'].setValue(null);
    //  }
    RegisterComponent.prototype.setFormLanguage = function () {
        var formLanguage = this._languageService.selectedLang == 'ru' ? Language.ruRU : Language.enUS;
        this.secondStepFormGroup.controls['language'].setValue(formLanguage);
        this._ref.detectChanges();
    };
    RegisterComponent.prototype.isEmailAvailable = function (email) {
        var emailControl = this.firstStepFormGroup.get('email');
        if (emailControl.hasError('required') || emailControl.hasError('email'))
            return;
        this._usersService.isEmailAvailable(email).pipe(takeUntil(this._unsubscribeAll)).subscribe(function (isAvailable) {
            if (isAvailable) {
                emailControl.setValidators([Validators.required, Validators.email]);
            }
            else {
                emailControl.setValidators([Validators.required, Validators.email, Validators.pattern("notAvailable")]);
            }
            emailControl.updateValueAndValidity();
        });
    };
    RegisterComponent.prototype.register = function () {
        var _this = this;
        if (this.firstStepFormGroup.invalid || this.secondStepFormGroup.invalid)
            return;
        this.registeringInProgress = true;
        var registerCommand = new RegisterUserCommand();
        registerCommand.managementOrganizationId = this._managementOrganizationId;
        ObjectMapper.mapFormGroupToObject(this.firstStepFormGroup, registerCommand);
        ObjectMapper.mapFormGroupToObject(this.secondStepFormGroup, registerCommand);
        this._usersService.register(registerCommand).pipe(takeUntil(this._unsubscribeAll)).subscribe(function (result) {
            _this._logger.info("Registration success!");
            _this.registeringInProgress = false;
            _this.isRegistrationSuccess = true;
        }, function (error) {
            _this._logger.info("Registration error!");
            _this.registeringInProgress = false;
        });
    };
    /**
     * On destroy
     */
    RegisterComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
