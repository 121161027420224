import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { SetCasesFilterParams } from '../store/actions/cases.actions';
import { selectCasesList } from '../store/selectors/cases.selector';
import { take, filter } from 'rxjs/operators';
import { CaseFolderType, PlannerModuleType } from '../models/planner/enums/plannerCaseEnums';
import { ResetUsersFilter } from '../store/actions/users.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
var CaseManagementResolver = /** @class */ (function () {
    function CaseManagementResolver(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    CaseManagementResolver.prototype.resolve = function (route, state) {
        var routeParams = route.params;
        this.logger.info('Case management route resolver: ', route.params);
        var obsCollection = [];
        obsCollection.push(this._store.select(selectCasesList).pipe(filter(function (cases) { return cases != null; }), take(1)));
        var folder = CaseFolderType.all;
        obsCollection.push(of(this._store.dispatch(new SetCasesFilterParams(folder, [PlannerModuleType.General, PlannerModuleType.Spine], true, true, true, ''))));
        this._store.dispatch(new ResetUsersFilter());
        return forkJoin(obsCollection);
    };
    CaseManagementResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaseManagementResolver_Factory() { return new CaseManagementResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: CaseManagementResolver, providedIn: "root" });
    return CaseManagementResolver;
}());
export { CaseManagementResolver };
