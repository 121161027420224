import * as tslib_1 from "tslib";
import { NativeDateAdapter } from '@angular/material';
var AppDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(AppDateAdapter, _super);
    function AppDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'input') {
            var day = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            var month = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            var year = date.getFullYear();
            return day + "/" + month + "/" + year;
        }
        return date.toDateString();
    };
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
