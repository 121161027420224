import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { PlannerSceneVisibilityGroup } from '@appmodels';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
var ɵ0 = APP_DATE_FORMATS;
var EditVisabilityGroupsComponent = /** @class */ (function () {
    function EditVisabilityGroupsComponent(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this.visibilityGroups = this._data.visibilityGroups;
        this.sceneObjects = _.orderBy(this._data.sceneObjects, ['foundationDate', function (obj) { return obj.title.toLowerCase(); }], ['desc', 'asc']);
        this.visabilityGroupsEditForm = this.createVisabilityGroupsEditForm(this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(EditVisabilityGroupsComponent.prototype, "visabilityGroupsFormArray", {
        get: function () {
            return this.visabilityGroupsEditForm.get('visibilityGroups').controls;
        },
        enumerable: true,
        configurable: true
    });
    EditVisabilityGroupsComponent.prototype.getArrayGroup = function (control, name) {
        return control.get(name).controls;
    };
    EditVisabilityGroupsComponent.prototype.ngOnInit = function () {
    };
    EditVisabilityGroupsComponent.prototype.createVisabilityGroupsEditForm = function (visibilityGroups) {
        var _this = this;
        var form = new FormGroup({});
        var visibilityGroupsFormArray = new FormArray([]);
        visibilityGroups.forEach(function (visibilityGroup) {
            var visibilityGroupFormGroup = new FormGroup({
                id: new FormControl(visibilityGroup.id),
                groupName: new FormControl(visibilityGroup.groupName, Validators.required),
                orderNum: new FormControl(visibilityGroup.orderNum, Validators.required),
            });
            var sceneObjectsVisibilityFormArray = new FormArray([]);
            _this.sceneObjects.forEach(function (sceneObject) {
                var isInGroup = visibilityGroup.sceneObjectsIds.indexOf(sceneObject.id) >= 0;
                var visibilityFormGroup = new FormGroup({
                    id: new FormControl(sceneObject.id),
                    sceneObjectTitle: new FormControl(sceneObject.title),
                    // foundationDate: new FormControl(sceneObject.foundationDate),
                    isInGroup: new FormControl(isInGroup)
                });
                sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
            });
            visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);
            visibilityGroupsFormArray.push(visibilityGroupFormGroup);
        });
        form.addControl("visibilityGroups", visibilityGroupsFormArray);
        return form;
    };
    EditVisabilityGroupsComponent.prototype.deleteVisabiltyGroup = function (groupIndex) {
        var formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        formArray.removeAt(groupIndex);
    };
    EditVisabilityGroupsComponent.prototype.addVisabiltyGroup = function () {
        var formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        var visibilityGroupFormGroup = new FormGroup({
            id: new FormControl(),
            groupName: new FormControl("New group", Validators.required),
            orderNum: new FormControl(0, Validators.required),
        });
        var sceneObjectsVisibilityFormArray = new FormArray([]);
        this.sceneObjects.forEach(function (sceneObject) {
            var visibilityFormGroup = new FormGroup({
                id: new FormControl(sceneObject.id),
                sceneObjectTitle: new FormControl(sceneObject.title),
                isInGroup: new FormControl(false)
            });
            sceneObjectsVisibilityFormArray.push(visibilityFormGroup);
        });
        visibilityGroupFormGroup.addControl('objectsVisibility', sceneObjectsVisibilityFormArray);
        formArray.push(visibilityGroupFormGroup);
    };
    EditVisabilityGroupsComponent.prototype.saveVisibilityGroups = function () {
        var visabilityGroups = [];
        var formArray = this.visabilityGroupsEditForm.controls['visibilityGroups'];
        formArray.controls.forEach(function (visabilityGroupFormGroup) {
            var visabilityGroup = new PlannerSceneVisibilityGroup();
            visabilityGroup.id = visabilityGroupFormGroup.get('id').value;
            visabilityGroup.groupName = visabilityGroupFormGroup.get('groupName').value;
            visabilityGroup.orderNum = visabilityGroupFormGroup.get('orderNum').value;
            visabilityGroup.sceneObjectsIds = [];
            var visibilityArray = visabilityGroupFormGroup.get('objectsVisibility');
            visibilityArray.controls.forEach(function (visibilityFormGroup) {
                var isInGroup = visibilityFormGroup.get('isInGroup').value;
                if (isInGroup) {
                    visabilityGroup.sceneObjectsIds.push(visibilityFormGroup.get('id').value);
                }
            });
            visabilityGroups.push(visabilityGroup);
        });
        this.matDialogRef.close(['save', visabilityGroups]);
    };
    EditVisabilityGroupsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return EditVisabilityGroupsComponent;
}());
export { EditVisabilityGroupsComponent };
export { ɵ0 };
