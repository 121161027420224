import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, ObjectMapper } from 'app/core/helpers';
import { SceneObjectType, MaterialQuality, FileType } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
var ɵ0 = APP_DATE_FORMATS;
var EditSceneObjectDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function EditSceneObjectDialogComponent(_caseService, matDialogRef, _data) {
        // Set the defaults
        this._caseService = _caseService;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this.sceneObjectTypeEnum = SceneObjectType;
        //  modelMaterialTypeEnum = ModelMaterialType;
        //  files: any;
        //dialogRef: any;
        this.fileTypeEnum = FileType;
        this.selectedCase = this._data.selectedCase;
        this.editedSceneObject = this._data.sceneObject;
        if (this.editedSceneObject)
            this.isEditingObject = true;
        this.sceneObjectEditForm = this.createSceneObjectEditForm(this.editedSceneObject);
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    EditSceneObjectDialogComponent.prototype.createSceneObjectEditForm = function (editedSceneObject) {
        var form = new FormGroup({
            id: new FormControl(null),
            title: new FormControl('', Validators.required),
            objectType: new FormControl(null, Validators.required),
            isVisible: new FormControl(true),
            objectFile: new FormControl(''),
            objectFileAdditional: new FormControl(''),
            description: new FormControl(''),
            color: new FormControl(''),
            text: new FormControl(''),
            position: new FormControl(''),
            rotation: new FormControl(''),
            scale: new FormControl(''),
            reflection: new FormControl(''),
            hierarchyInfo: new FormControl(''),
            materialQuality: new FormControl(MaterialQuality.Good),
            idForSelectionGroup: new FormControl(''),
        });
        var objectTypeValChanges$ = form.controls.objectType.valueChanges;
        objectTypeValChanges$.subscribe(function (newObjectType) {
            form.controls['objectFile'].clearValidators();
            switch (newObjectType) {
                case SceneObjectType.Object3d:
                    form.controls['objectFile'].setValidators(Validators.required);
                    break;
                default:
                    break;
            }
            form.controls['objectFile'].updateValueAndValidity();
        });
        if (editedSceneObject) {
            ObjectMapper.mapObjectToFormGroup(editedSceneObject, form);
            if (editedSceneObject.objectFile) {
                var fileToAttach = this.selectedCase.details.files.filter(function (x) { return x.id == editedSceneObject.objectFile.id; })[0];
                form.controls['objectFile'].setValue(fileToAttach);
            }
            if (editedSceneObject.objectFileAdditional) {
                var fileAdditionalToAttach = this.selectedCase.details.files.filter(function (x) { return x.id == editedSceneObject.objectFileAdditional.id; })[0];
                form.controls['objectFileAdditional'].setValue(fileAdditionalToAttach);
            }
        }
        return form;
    };
    EditSceneObjectDialogComponent.prototype.editSceneObject = function () {
        if (!this.editedSceneObject) {
            this.editedSceneObject = new PlannerSceneObject();
        }
        ObjectMapper.mapFormGroupToObject(this.sceneObjectEditForm, this.editedSceneObject);
        this.matDialogRef.close([this.isEditingObject ? 'edit' : 'add', this.editedSceneObject]);
    };
    EditSceneObjectDialogComponent.prototype.setSceneObjectColor = function (color) {
        this.sceneObjectEditForm.controls['color'].setValue(color);
    };
    EditSceneObjectDialogComponent.prototype.filterByFileType = function (plannerFiles) {
        return plannerFiles.sort(function (a, b) { return a.fileType > b.fileType ? 1 : a.fileType == b.fileType ? 0 : -1; });
    };
    EditSceneObjectDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return EditSceneObjectDialogComponent;
}());
export { EditSceneObjectDialogComponent };
export { ɵ0 };
