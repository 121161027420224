import * as tslib_1 from "tslib";
import { FilesService } from 'app/core/services/planner/files.sevice';
import { DeleteFile, EFilesActions, EditFile, EditFilesPermissions } from '../actions/files.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSelectedCase } from '../selectors/cases.selector';
import { SelectCase } from '../actions/cases.actions';
import { VoidAction } from '../actions/app.actions';
import { NewNotification } from '../actions/notifications.actions';
import { DeletePlannerFileCommand, EditPlannerFileCommand, EditPlannerFilePermissionsCommand } from '@appmodels';
import { ObjectMapper } from 'app/core/helpers';
var FilesEffects = /** @class */ (function () {
    function FilesEffects(_filesService, _actions$, _store) {
        var _this = this;
        this._filesService = _filesService;
        this._actions$ = _actions$;
        this._store = _store;
        this.editFile$ = this._actions$.pipe(ofType(EFilesActions.EditFile), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(function (_a) {
            var fileToEdit = _a[0], selectedCase = _a[1];
            var editFileCommand = new EditPlannerFileCommand();
            ObjectMapper.mapObjectToObject(fileToEdit, editFileCommand);
            editFileCommand.caseId = selectedCase.id;
            return _this._filesService.editFile(editFileCommand).pipe(
            // tap(res => console.log(res)),
            switchMap(function (result) {
                if (result.isSuccess) {
                    _this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    _this._store.dispatch(new NewNotification({ type: 'FileEdit', message: 'File edit error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap(function (selectedCase) { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
        this.deleteFile$ = this._actions$.pipe(ofType(EFilesActions.DeleteFile), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(function (_a) {
            var fileToDelete = _a[0], selectedCase = _a[1];
            var deleteFileCommand = new DeletePlannerFileCommand();
            deleteFileCommand.fileId = fileToDelete.id;
            deleteFileCommand.caseId = selectedCase.id;
            return _this._filesService.deleteFile(deleteFileCommand).pipe(
            // tap(res => console.log(res)),
            switchMap(function (result) {
                if (result.isSuccess) {
                    _this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    _this._store.dispatch(new NewNotification({ type: 'FileDelete', message: 'File delete error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap(function (selectedCase) { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
        this.editFilesPermissions$ = this._actions$.pipe(ofType(EFilesActions.EditFilesPermissions), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedCase)), switchMap(function (_a) {
            var filesAllowUserDownloadAction = _a[0], selectedCase = _a[1];
            var editPlannerFilePermissionsCommand = new EditPlannerFilePermissionsCommand();
            editPlannerFilePermissionsCommand.caseId = selectedCase.id;
            editPlannerFilePermissionsCommand.filesAllowUserDownload = filesAllowUserDownloadAction;
            return _this._filesService.editPlannerFilePermissions(editPlannerFilePermissionsCommand).pipe(switchMap(function (result) {
                if (result.isSuccess) {
                    _this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions success!', action: 'Ok' }));
                    return of(selectedCase);
                }
                else {
                    _this._store.dispatch(new NewNotification({ type: 'EditFilesPermissions', message: 'Edit files permissions error! See the error in the console log.', action: 'Error' }));
                    return of(null);
                }
            }));
        }), switchMap(function (selectedCase) { return [selectedCase != null ? (new SelectCase(selectedCase.id)) : (new VoidAction())]; }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffects.prototype, "editFile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffects.prototype, "deleteFile$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], FilesEffects.prototype, "editFilesPermissions$", void 0);
    return FilesEffects;
}());
export { FilesEffects };
