import { OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, DateValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, PlannerCase, PlannerCaseRelatedData, ICaseSerializedDataObject, ICaseOrder } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
var ɵ0 = APP_DATE_FORMATS;
var CaseEditDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function CaseEditDialogComponent(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        var _this = this;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.medicalReferalsEnum = MedicalReferals;
        this.operationTypeEnum = OperationType;
        this.sceneTypeEnum = SceneType;
        this.genderEnum = Gender;
        this.medicalReferalsTypeEnum = MedicalReferals;
        this.validateUser = function (control) {
            if (!control || !control.parent) {
                return null;
            }
            var userEmail = control.parent.get('owner').value;
            var isValid = false;
            if (!_this.foundUsers || _this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                var foundUsersIndex = _this.foundUsers.findIndex(function (user) { return user.email == userEmail; });
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        this.caseEditForm = this.createCaseEditForm(_data);
        this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    Object.defineProperty(CaseEditDialogComponent.prototype, "users", {
        set: function (value) {
            this.foundUsers = value;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    CaseEditDialogComponent.prototype.createCaseEditForm = function (caseToEdit) {
        var caseEditForm = new FormGroup({
            // owner: new FormControl({
            //     value: 'admin@admin.ru',
            //     disabled: false
            // }),
            owner: new FormControl('', [Validators.required, this.validateUser]),
            title: new FormControl('', Validators.required),
            medicalReferalsType: new FormControl(1, Validators.required),
            operationType: new FormControl(1, Validators.required),
            sceneType: new FormControl(1, Validators.required),
            planning: new FormControl(true, Validators.required),
            anatomicalModel: new FormControl(true, Validators.required),
            guide: new FormControl(true, Validators.required),
            titan: new FormControl(true, Validators.required),
            peek: new FormControl(true, Validators.required),
            surgicalDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),
            description: new FormControl(''),
            patientName: new FormControl('Unknown'),
            patientCode: new FormControl(''),
            isAllowAll: new FormControl(false),
            isArchived: new FormControl(false),
            isExcludeFromAnalytics: new FormControl(false),
            gender: new FormControl(''),
            service: new FormControl(''),
            procedures: new FormControl(''),
            birthday: new FormControl(null, DateValidator.dateVaidator),
            surgicalSide: new FormControl(''),
            scanCenter: new FormControl(''),
            shipment: new FormControl(''),
        });
        if (caseToEdit) {
            this.editedCase = caseToEdit;
            this.isEditingCase = true;
            ObjectMapper.mapObjectToFormGroup(caseToEdit, caseEditForm);
            ObjectMapper.mapObjectToFormGroup(caseToEdit.details, caseEditForm);
            var serializedDataObject = JSON.parse(caseToEdit.details.serializedData);
            if (!serializedDataObject || !serializedDataObject.order) {
                serializedDataObject = {};
                serializedDataObject.order = { planning: false, anatomicalModel: false, guide: false, titan: false, peek: false };
            }
            caseEditForm.get('planning').setValue(serializedDataObject.order.planning);
            caseEditForm.get('anatomicalModel').setValue(serializedDataObject.order.anatomicalModel);
            caseEditForm.get('guide').setValue(serializedDataObject.order.guide);
            caseEditForm.get('titan').setValue(serializedDataObject.order.titan);
            caseEditForm.get('peek').setValue(serializedDataObject.order.peek ? serializedDataObject.order.peek : false);
            caseEditForm.get('owner').setValue(this.editedCase.ownerName);
            caseEditForm.get('owner').disable();
        }
        return caseEditForm;
    };
    /**
     * Toggle extra to fields
     */
    CaseEditDialogComponent.prototype.toggleExtraToFields = function () {
        this.showExtraToFields = !this.showExtraToFields;
    };
    CaseEditDialogComponent.prototype.createCase = function () {
        if (this.caseEditForm.invalid)
            return;
        var newCase = new PlannerCase();
        this.fillCaseFields(newCase);
        var userEmail = this.caseEditForm.get('owner').value;
        var selectedUser = this.foundUsers.find(function (user) { return user.email == userEmail; });
        newCase.ownerId = selectedUser.id;
        this.matDialogRef.close(['create', newCase]);
    };
    CaseEditDialogComponent.prototype.editCase = function () {
        if (this.caseEditForm.invalid)
            return;
        var editedCase = new PlannerCase();
        this.fillCaseFields(editedCase);
        editedCase.id = this.editedCase.id;
        this.matDialogRef.close(['edit', editedCase]);
    };
    CaseEditDialogComponent.prototype.fillCaseFields = function (editedCase) {
        ObjectMapper.mapFormGroupToObject(this.caseEditForm, editedCase);
        editedCase.details = new PlannerCaseRelatedData();
        ////////// serialized data //////////
        var planning = this.caseEditForm.get('planning').value;
        var anatomicalModel = this.caseEditForm.get('anatomicalModel').value;
        var guide = this.caseEditForm.get('guide').value;
        var titan = this.caseEditForm.get('titan').value;
        var peek = this.caseEditForm.get('peek').value;
        editedCase.details.serializedData = JSON.stringify({ order: { planning: planning, anatomicalModel: anatomicalModel, guide: guide, titan: titan, peek: peek } });
        /////////////////////////////////////
        ObjectMapper.mapFormGroupToObject(this.caseEditForm, editedCase.details);
    };
    CaseEditDialogComponent.prototype.onSearchQuery = function (searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    };
    CaseEditDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return CaseEditDialogComponent;
}());
export { CaseEditDialogComponent };
export { ɵ0 };
