import { HttpClient } from "@angular/common/http";
import { AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { map, tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
var LongRunningTasksService = /** @class */ (function () {
    function LongRunningTasksService(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    LongRunningTasksService.prototype.getLongRunningTaskInfo = function (taskId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/LongRunningTasks/GetLongRunningTaskInfo?TaskId=" + taskId;
        this.logger.debug("HTTP GET get long running task info", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response get long running task info:", response);
        }), map(function (response) { return response; }));
    };
    LongRunningTasksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LongRunningTasksService_Factory() { return new LongRunningTasksService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: LongRunningTasksService, providedIn: "root" });
    return LongRunningTasksService;
}());
export { LongRunningTasksService };
