import { OnDestroy, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, ObjectMapper, } from 'app/core/helpers';
import { PlannerCase, FileType, ModelMaterialType, PlannerFile } from '@appmodels';
import { Subject } from 'rxjs';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { NGXLogger } from 'ngx-logger';
var ɵ0 = APP_DATE_FORMATS;
var EditFileDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function EditFileDialogComponent(_filesService, matDialogRef, _data, logger) {
        this._filesService = _filesService;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this.logger = logger;
        this.fileTypeEnum = FileType;
        this.modelMaterialTypeEnum = ModelMaterialType;
        // Set the defaults
        this._selectedCase = _data.selectedCase;
        this.plannerFile = _data.plannerFile;
        this.fileEditForm = this.createFilesEditForm(this.plannerFile);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(EditFileDialogComponent.prototype, "fileAdditionalInfoGroup", {
        get: function () {
            return this.fileEditForm.controls['fileAdditionalInfoGroup'];
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    EditFileDialogComponent.prototype.createFilesEditForm = function (plannerFile) {
        var _this = this;
        if (!plannerFile)
            return null;
        var fileFormGroup = new FormGroup({
            title: new FormControl(plannerFile.title, Validators.required)
        });
        fileFormGroup.addControl('fileType', new FormControl(plannerFile.fileType, Validators.required));
        var fileAdditionalInfoGroupName = 'fileAdditionalInfoGroup';
        var additionalInfo = JSON.parse(plannerFile.additionalInfo);
        if (!additionalInfo)
            additionalInfo = {};
        var fileAdditionalInfoGroup = new FormGroup({
            isUseIn3d: new FormControl(additionalInfo.isUseIn3d),
            materialType: new FormControl(additionalInfo.materialType),
            saw: new FormControl(additionalInfo.saw),
            pin: new FormControl(additionalInfo.pin),
            screw: new FormControl(additionalInfo.screw)
        });
        fileFormGroup.addControl(fileAdditionalInfoGroupName, fileAdditionalInfoGroup);
        var changes$ = fileFormGroup.controls.fileType.valueChanges;
        changes$.subscribe(function (newFileType) {
            _this.updateValidatorOnFileType(fileAdditionalInfoGroup, newFileType);
        });
        this.updateValidatorOnFileType(fileAdditionalInfoGroup, plannerFile.fileType);
        return fileFormGroup;
    };
    EditFileDialogComponent.prototype.updateValidatorOnFileType = function (fileAdditionalInfoGroup, newFileType) {
        this.logger.info('File type changed!');
        fileAdditionalInfoGroup.controls['materialType'].clearValidators();
        switch (newFileType) {
            case FileType.STLMODEL:
                fileAdditionalInfoGroup.controls['materialType'].setValidators(Validators.required);
                break;
            default:
                break;
        }
        fileAdditionalInfoGroup.controls['materialType'].updateValueAndValidity();
    };
    EditFileDialogComponent.prototype.editFile = function () {
        if (this.fileEditForm.invalid)
            return;
        this.requestInProgress = true;
        var editedPLannerFile = new PlannerFile();
        editedPLannerFile.id = this.plannerFile.id;
        // editedPLannerFile.fileName = this.plannerFile.fileName;
        // editedPLannerFile.fileExtension = this.plannerFile.fileExtension;
        // editedPLannerFile.fileSize = this.plannerFile.fileSize;
        // editedPLannerFile. creationDate = this.plannerFile. creationDate;
        // editedPLannerFile.md5hash = this.plannerFile.md5hash;
        // editedPLannerFile.version = this.plannerFile.version;
        // editedPLannerFile.isZipped = this.plannerFile.isZipped;
        // editedPLannerFile.fileSizeZipped = this.plannerFile.fileSizeZipped;
        // editedPLannerFile.fileAeskey = this.plannerFile.fileAeskey;
        ObjectMapper.mapFormGroupToObject(this.fileEditForm, editedPLannerFile);
        var additionalInfoObj = {};
        var fileAdditionalInfoGroup = this.fileAdditionalInfoGroup;
        var needToZip = false;
        switch (editedPLannerFile.fileType) {
            case FileType.DICOM:
                additionalInfoObj.isUseIn3d = fileAdditionalInfoGroup.controls['isUseIn3d'].value;
                break;
            case FileType.STLMODEL:
                needToZip = true;
                additionalInfoObj.materialType = fileAdditionalInfoGroup.controls['materialType'].value;
                additionalInfoObj.saw = fileAdditionalInfoGroup.controls['saw'].value;
                additionalInfoObj.pin = fileAdditionalInfoGroup.controls['pin'].value;
                additionalInfoObj.screw = fileAdditionalInfoGroup.controls['screw'].value;
                break;
            default:
                break;
        }
        var additionalInfo = JSON.stringify(additionalInfoObj);
        editedPLannerFile.additionalInfo = additionalInfo;
        this.matDialogRef.close(['edit', editedPLannerFile]);
    };
    EditFileDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return EditFileDialogComponent;
}());
export { EditFileDialogComponent };
export { ɵ0 };
