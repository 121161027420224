import { OnDestroy, OnInit, TemplateRef, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaidModulesFormDialogComponent } from '../paidModules-form/paidModules-form.component';
import { PlannerModuleType, PlannerUsersAvailableModules } from '@appmodels';
import { ConfirmDialogComponent } from 'app/core/shared';
var PaidModulesListComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {MatDialog} _matDialog
     */
    function PaidModulesListComponent(_matDialog) {
        this._matDialog = _matDialog;
        //user: any;
        this.displayedColumns = ['email', 'name', 'hospital', 'moduleType', 'startDate', 'daysAllocated', 'daysRemaining', 'buttons'];
        this.moduleTypeEnum = PlannerModuleType;
        this.paidModules = [];
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.users$ = new Subject();
        this.onUsersPaidModuleEdit = new EventEmitter();
        this.onUsersPaidModuleDelete = new EventEmitter();
        this.onUsersSearch = new EventEmitter();
    }
    Object.defineProperty(PaidModulesListComponent.prototype, "users", {
        set: function (value) {
            this.users$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    PaidModulesListComponent.prototype.ngOnInit = function () {
    };
    /**
     * On destroy
     */
    PaidModulesListComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Edit user
     *
     * @param user
     */
    PaidModulesListComponent.prototype.editUser = function (user) {
        var _this = this;
        this.dialogRef = this._matDialog.open(PaidModulesFormDialogComponent, {
            panelClass: 'paidModules-form-dialog',
            data: {
                paidModule: user,
                action: 'edit'
            }
        });
        var instance = this.dialogRef.componentInstance;
        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (users) {
            instance.users = users;
        });
        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (query) {
            _this.onUsersSearch.emit(query);
        });
        this.dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            switch (actionType) {
                /**
                 * Save
                 */
                case 'save':
                    var editedUsersPaidModule = response[1];
                    _this.onUsersPaidModuleEdit.emit(editedUsersPaidModule);
                    break;
                /**
                 * Delete
                 */
                case 'cancel':
                    //this.deleteContact(user);
                    break;
            }
        });
    };
    PaidModulesListComponent.prototype.deleteUsersPaidModule = function (paidModule) {
        var _this = this;
        var confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: "Are you sure you want to delete user paid module?"
        });
        confirmDialogRef.afterClosed()
            .subscribe(function (confirmResult) {
            if (!confirmResult) {
                return;
            }
            _this.onUsersPaidModuleDelete.emit(paidModule);
        });
    };
    /**
     * Delete Contact
     */
    // deleteContact(contact): void {
    //     this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
    //         disableClose: false
    //     });
    //     this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    //     this.confirmDialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             // this._contactsService.deleteContact(contact);
    //         }
    //         this.confirmDialogRef = null;
    //     });
    // }
    /**
     * On selected change
     *
     * @param contactId
     */
    PaidModulesListComponent.prototype.onSelectedChange = function (contactId) {
        //  this._contactsService.toggleSelectedContact(contactId);
    };
    return PaidModulesListComponent;
}());
export { PaidModulesListComponent };
