import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { Subject } from 'rxjs';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
var ɵ0 = APP_DATE_FORMATS;
var EditFilePermissionsComponent = /** @class */ (function () {
    function EditFilePermissionsComponent(matDialogRef, _data) {
        this.matDialogRef = matDialogRef;
        this._data = _data;
        //this.visibilityGroups = this._data.visibilityGroups;
        // console.log();
        this.caseFiles = _.orderBy(this._data.caseFiles, ['foundationDate', function (obj) { return obj.title.toLowerCase(); }], ['desc', 'asc']);
        this.editFilePermissionsForm = this.createEditFilePermissionsForm(); //this.visibilityGroups);
        this._unsubscribeAll = new Subject();
    }
    EditFilePermissionsComponent.prototype.getArrayGroup = function (control, name) {
        return control.get(name).controls;
    };
    EditFilePermissionsComponent.prototype.ngOnInit = function () {
    };
    EditFilePermissionsComponent.prototype.createEditFilePermissionsForm = function () {
        var editFilePermissionsFormGroup = new FormGroup({});
        var editFilePermissionsFormArray = new FormArray([]);
        this.caseFiles.forEach(function (plannerFile) {
            var editFilePermissionsGroup = new FormGroup({
                id: new FormControl(plannerFile.id),
                sceneObjectTitle: new FormControl(plannerFile.title),
                isAllowUserDownload: new FormControl(plannerFile.isAllowUserDownload)
            });
            editFilePermissionsFormArray.push(editFilePermissionsGroup);
        });
        editFilePermissionsFormGroup.addControl('editFilePermissions', editFilePermissionsFormArray);
        return editFilePermissionsFormGroup;
    };
    EditFilePermissionsComponent.prototype.saveFilesPermissions = function () {
        var formArray = this.editFilePermissionsForm.controls['editFilePermissions'];
        var filesAllowUserDownload = [];
        formArray.controls.forEach(function (editFilePermissionsGroup) {
            var fileId = editFilePermissionsGroup.get('id').value;
            var isActionAllowed = editFilePermissionsGroup.get('isAllowUserDownload').value;
            var fileAllowUserDownload = { fileId: fileId, isActionAllowed: isActionAllowed };
            filesAllowUserDownload.push(fileAllowUserDownload);
        });
        this.matDialogRef.close(['save', filesAllowUserDownload]);
    };
    EditFilePermissionsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return EditFilePermissionsComponent;
}());
export { EditFilePermissionsComponent };
export { ɵ0 };
