import * as i0 from "@angular/core";
var SettingsService = /** @class */ (function () {
    function SettingsService() {
        this.ModulesFiltersSetting = 'modulesFilters';
    }
    SettingsService.prototype.getModulesFilters = function () {
        var loadedModulesFiletrs = JSON.parse(localStorage.getItem(this.ModulesFiltersSetting));
        if (loadedModulesFiletrs)
            return loadedModulesFiletrs;
        return [];
    };
    SettingsService.prototype.setModulesFilters = function (modulesFilters) {
        localStorage.setItem(this.ModulesFiltersSetting, JSON.stringify(modulesFilters));
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
