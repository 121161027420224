import { HttpClient } from '@angular/common/http';
import { AppConfig } from "app/app-config.module";
import { NGXLogger } from "ngx-logger";
import { map, tap } from "rxjs/operators";
import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
var PaidModulesService = /** @class */ (function () {
    function PaidModulesService(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    PaidModulesService.prototype.getUsersPaidModules = function (searchPattern, skip, take) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/GetUsersAvailablePaidModules?SearchPattern=" + searchPattern + "&Sort=DaysAllocated&SortOrder=0&Skip=" + skip + "&Take=" + take;
        this.logger.debug("HTTP GET users paid modules.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response users paid modules:", response);
        }), map(function (response) { return response; }));
    };
    PaidModulesService.prototype.addUsersPaidModule = function (addUserAvailablePaidModuleCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/AddUserAvailablePaidModules";
        this.logger.debug("HTTP POST add user paid module.", "New user paid module: " + addUserAvailablePaidModuleCommand + ",", "url: " + url);
        return this.http.post(url, addUserAvailablePaidModuleCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response add user paid modules:", response);
        }), map(function (response) { return response; }));
    };
    PaidModulesService.prototype.editUsersPaidModule = function (editUserAvailablePaidModuleCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/EditUserAvailablePaidModules";
        this.logger.debug("HTTP POST edit user paid module.", "Edit user paid module: " + editUserAvailablePaidModuleCommand + ",", "url: " + url);
        return this.http.post(url, editUserAvailablePaidModuleCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response edit user paid modules:", response);
        }), map(function (response) { return response; }));
    };
    PaidModulesService.prototype.removeUsersPaidModule = function (removeUserAvailablePaidModuleCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/RemoveUserAvailablePaidModules";
        this.logger.debug("HTTP POST remove user paid module.", "Remove user paid module: " + removeUserAvailablePaidModuleCommand + ",", "url: " + url);
        return this.http.post(url, removeUserAvailablePaidModuleCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response remove user paid modules:", response);
        }), map(function (response) { return response; }));
    };
    PaidModulesService.prototype.getUsersPaidModulesOffline = function (searchPattern, skip, take) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/GetUsersAvailablePaidModulesOffline?SearchPattern=" + searchPattern + "&Sort=DaysAllocated&SortOrder=0&Skip=" + skip + "&Take=" + take;
        this.logger.debug("HTTP GET users paid modules offline.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response users paid modules offline:", response);
        }), map(function (response) { return response; }));
    };
    PaidModulesService.prototype.generateLicense = function (generateModuleLicenseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/PaidServicesManagement/GenerateModuleLicense";
        this.logger.debug("HTTP POST generate user license.", "Generate license: " + generateModuleLicenseCommand + ",", "url: " + url);
        return this.http.post(url, generateModuleLicenseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Generate license:", response);
        }), tap(function (response) {
            var blob = new Blob([response.token], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, "bonabyte-planner.license");
        }));
    };
    PaidModulesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaidModulesService_Factory() { return new PaidModulesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: PaidModulesService, providedIn: "root" });
    return PaidModulesService;
}());
export { PaidModulesService };
