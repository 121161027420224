import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { PlannerCaseShare } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
var ɵ0 = APP_DATE_FORMATS;
var CaseShareDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function CaseShareDialogComponent(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        var _this = this;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.validateUser = function (control) {
            if (!control || !control.parent) {
                return null;
            }
            var userEmail = control.parent.get('userToShare').value;
            var isValid = false;
            if (!_this.foundUsers || _this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                var foundUsersIndex = _this.foundUsers.findIndex(function (user) { return user.email == userEmail; });
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        _data.title;
        this.caseShares = _data.caseShares;
        this.caseShareForm = this.createCaseShareForm(this.caseShares);
        this.addCaseShareForm = this.createAddCaseShareForm();
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    Object.defineProperty(CaseShareDialogComponent.prototype, "users", {
        set: function (value) {
            this.foundUsers = value;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CaseShareDialogComponent.prototype, "caseSharesEntries", {
        get: function () {
            return this.caseShareForm.get('caseSharesEntries').controls;
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    CaseShareDialogComponent.prototype.createCaseShareForm = function (caseShares) {
        var caseSharesFormGroups = new FormGroup({});
        var caseSharesFormGroupsArray = new FormArray([]);
        caseShares.forEach(function (caseShare) {
            var shareFormGroup = new FormGroup({
                // newTitle: new FormControl(title, Validators.required),
                userToShare: new FormControl({ value: caseShare.shareToName, disabled: true }, [Validators.required]),
                userToShareId: new FormControl({ value: caseShare.shareToId, disabled: true }, [Validators.required])
            });
            caseSharesFormGroupsArray.push(shareFormGroup);
        });
        caseSharesFormGroups.addControl('caseSharesEntries', caseSharesFormGroupsArray);
        return caseSharesFormGroups;
    };
    CaseShareDialogComponent.prototype.createAddCaseShareForm = function () {
        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl('', [Validators.required])
        });
        return shareFormGroup;
    };
    CaseShareDialogComponent.prototype.onSearchQuery = function (searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    };
    CaseShareDialogComponent.prototype.addShareEntry = function () {
        if (this.addCaseShareForm.invalid)
            return;
        var formArray = this.caseSharesEntries;
        //  const newTitle = this.caseShareForm.get('newTitle').value;
        var userEmail = this.addCaseShareForm.get('userToShare').value;
        var selectedUser = this.foundUsers.find(function (user) { return user.email == userEmail; });
        var shareFormGroup = new FormGroup({
            // newTitle: new FormControl(title, Validators.required),
            userToShare: new FormControl({ value: userEmail, disabled: true }, [Validators.required]),
            userToShareId: new FormControl({ value: selectedUser.id, disabled: true }, [Validators.required])
        });
        formArray.push(shareFormGroup);
    };
    CaseShareDialogComponent.prototype.deleteShareEntry = function (index) {
        var formArray = this.caseSharesEntries;
        formArray.splice(index, 1);
    };
    CaseShareDialogComponent.prototype.onSubmit = function () {
        //console.log(this.caseShareForm);
        if (this.caseShareForm.invalid)
            return;
        //  const newTitle = this.caseShareForm.get('newTitle').value;
        //   const userEmail = this.addCaseShareForm.get('userToShare').value;
        //  const selectedUser = this.foundUsers.find(user => user.email == userEmail);
        var formArray = this.caseSharesEntries;
        var usersToShare = [];
        formArray.forEach(function (control) {
            var userToShareId = control.get('userToShareId').value;
            //const userToShare = this.foundUsers.find(user => user.email == userToShareName);
            usersToShare.push(userToShareId);
        });
        this.matDialogRef.close(['share', usersToShare]);
    };
    CaseShareDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return CaseShareDialogComponent;
}());
export { CaseShareDialogComponent };
export { ɵ0 };
