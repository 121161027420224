import { OnDestroy, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, } from 'app/core/helpers';
import { FileType, ModelMaterialType } from '@appmodels';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { takeUntil, finalize, map, catchError, switchMap } from 'rxjs/operators';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { NGXLogger } from 'ngx-logger';
import { GeometryService } from 'app/core/services/planner/geometry.service';
var ɵ0 = APP_DATE_FORMATS;
var AddFileDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function AddFileDialogComponent(_filesService, matDialogRef, _data, logger) {
        this._filesService = _filesService;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this.logger = logger;
        this.fileTypeEnum = FileType;
        this.modelMaterialTypeEnum = ModelMaterialType;
        // Set the defaults
        this._selectedCase = _data;
        this.filesEditForm = this.createFilesEditForm(null);
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    AddFileDialogComponent.prototype.createFilesEditForm = function (files) {
        var _this = this;
        this.filesIndices = [];
        var parentFormGroup = new FormGroup({});
        if (!files)
            return parentFormGroup;
        var fileIndex = 1;
        files.forEach(function (fileToUpload) {
            var fileExtensionIndex = fileToUpload.name.lastIndexOf('.');
            var fileExtension = fileToUpload.name.substring(fileExtensionIndex + 1, fileToUpload.name.length);
            var title = fileToUpload.name.substring(0, fileExtensionIndex);
            var fileFormGroup = new FormGroup({
                title: new FormControl(title, Validators.required)
            });
            _this.logger.info('fileExtension: ' + fileExtension);
            var fileType = fileExtension == 'stl' || fileExtension == 'ply' ? FileType.STLMODEL : null;
            var defaultMaterialType = null;
            if (fileType == FileType.STLMODEL) {
                var titleLower = title.toLowerCase();
                if (/\sP\d\d\s/.test(title) || titleLower.includes('peek') || titleLower.includes('пик') || titleLower.includes('cage')) {
                    defaultMaterialType = ModelMaterialType.PEEK;
                }
                else if (/\sT\d\d\s/.test(title) || titleLower.includes('cup') || titleLower.includes('prosthesis') || titleLower.includes('implant') ||
                    titleLower.includes('plate') || titleLower.includes('insert') || titleLower.includes('screw') ||
                    titleLower.includes('stem') || titleLower.includes('wedge') || titleLower.includes('pgr')) {
                    defaultMaterialType = ModelMaterialType.Titanium;
                }
                else if (/\sG\d\d\s/.test(title) || titleLower.includes('guide')) {
                    defaultMaterialType = ModelMaterialType.Plastic;
                }
                else if (/\sF\d\d\s/.test(title)) {
                    defaultMaterialType = ModelMaterialType.Gypsum;
                }
                else {
                    defaultMaterialType = ModelMaterialType.Bone;
                }
            }
            fileFormGroup.addControl('fileType', new FormControl(null, Validators.required));
            var fileAdditionalInfoGroupName = 'fileAdditionalInfoGroup';
            var fileAdditionalInfoGroup = new FormGroup({
                isUseIn3d: new FormControl(false),
                materialType: new FormControl(defaultMaterialType),
                saw: new FormControl(null),
                pin: new FormControl(null),
                screw: new FormControl(null)
            });
            fileFormGroup.addControl(fileAdditionalInfoGroupName, fileAdditionalInfoGroup);
            var changes$ = fileFormGroup.controls.fileType.valueChanges;
            changes$.subscribe(function (newFileType) {
                _this.logger.info('File type changed!');
                fileAdditionalInfoGroup.controls['materialType'].clearValidators();
                switch (newFileType) {
                    case FileType.STLMODEL:
                        fileAdditionalInfoGroup.controls['materialType'].setValidators(Validators.required);
                        break;
                    default:
                        break;
                }
                fileAdditionalInfoGroup.controls['materialType'].updateValueAndValidity();
            });
            fileFormGroup.controls.fileType.setValue(fileType);
            var groupName = 'file' + fileIndex;
            parentFormGroup.addControl(groupName, fileFormGroup);
            _this.filesIndices.push({ groupName: groupName, file: fileToUpload });
            fileIndex++;
        });
        return parentFormGroup;
    };
    AddFileDialogComponent.prototype.filesSelected = function (files) {
        this.logger.info('files selected, count: ' + files.length);
        this.filesEditForm = this.createFilesEditForm(files);
    };
    AddFileDialogComponent.prototype.removeFiles = function () {
        this.createFilesEditForm(null);
    };
    AddFileDialogComponent.prototype.addFiles = function (isAddToScene) {
        var _this = this;
        if (this.filesEditForm.invalid)
            return;
        this.requestInProgress = true;
        var fileUploads = [];
        this.filesIndices.forEach(function (x) {
            fileUploads.push(_this.uploadFile(x));
        });
        forkJoin(fileUploads).pipe(takeUntil(this._unsubscribeAll), finalize(function () {
            _this.requestInProgress = false;
        }))
            .subscribe({
            next: function (result) {
                result.forEach(function (r) { return console.log(r); });
                if (isAddToScene) {
                    _this.matDialogRef.close(['upload&add', true, result]);
                }
                else {
                    _this.matDialogRef.close(['upload', true]);
                }
            },
            error: function (err) {
                _this.logger.info(err);
                _this.errorText = err;
            }
        });
    };
    AddFileDialogComponent.prototype.uploadFile = function (filesIndex) {
        var _this = this;
        var fileGroup = this.filesEditForm.controls[filesIndex.groupName];
        var file = filesIndex.file;
        var title = fileGroup.controls['title'].value;
        var fileType = fileGroup.controls['fileType'].value;
        var additionalInfoObj = {};
        var fileAdditionalInfoGroup = fileGroup.controls['fileAdditionalInfoGroup'];
        var preprocess = of(file);
        var needToZip = false;
        switch (fileType) {
            case FileType.DICOM:
                additionalInfoObj.isUseIn3d = fileAdditionalInfoGroup.controls['isUseIn3d'].value;
                break;
            case FileType.STLMODEL:
                if (file.name.toLowerCase().lastIndexOf('.stl') == file.name.length - 4)
                    preprocess = new GeometryService().convertToPLY(file);
                needToZip = true;
                additionalInfoObj.materialType = fileAdditionalInfoGroup.controls['materialType'].value;
                additionalInfoObj.saw = fileAdditionalInfoGroup.controls['saw'].value;
                additionalInfoObj.pin = fileAdditionalInfoGroup.controls['pin'].value;
                additionalInfoObj.screw = fileAdditionalInfoGroup.controls['screw'].value;
                break;
            default:
                break;
        }
        var additionalInfo = JSON.stringify(additionalInfoObj);
        var piped = preprocess.pipe(switchMap(function (res) { return _this._filesService.uploadFile(res, _this._selectedCase.id, title, fileType, additionalInfo, needToZip).pipe(map(function (fileResponse) {
            filesIndex.fileResponse = fileResponse;
            return fileResponse;
        }), catchError(function (err) {
            filesIndex.fileResponse = null;
            throw err;
        })); }));
        return piped;
    };
    AddFileDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return AddFileDialogComponent;
}());
export { AddFileDialogComponent };
export { ɵ0 };
