import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
var ɵ0 = APP_DATE_FORMATS;
var CaseCloneDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<CaseEditDialogComponent>} matDialogRef
     * @param _data
     */
    function CaseCloneDialogComponent(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        var _this = this;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        this.medicalReferalsEnum = MedicalReferals;
        this.operationTypeEnum = OperationType;
        this.sceneTypeEnum = SceneType;
        this.genderEnum = Gender;
        this.medicalReferalsTypeEnum = MedicalReferals;
        this.validateUser = function (control) {
            if (!control || !control.parent) {
                return null;
            }
            var userEmail = control.parent.get('userToClone').value;
            var isValid = false;
            if (!_this.foundUsers || _this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                var foundUsersIndex = _this.foundUsers.findIndex(function (user) { return user.email == userEmail; });
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        _data.title;
        this.caseCloneForm = this.createCaseCloneForm(_data.title);
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    Object.defineProperty(CaseCloneDialogComponent.prototype, "users", {
        set: function (value) {
            this.foundUsers = value;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    CaseCloneDialogComponent.prototype.createCaseCloneForm = function (title) {
        return new FormGroup({
            newTitle: new FormControl(title, Validators.required),
            userToClone: new FormControl('', [Validators.required, this.validateUser])
        });
    };
    CaseCloneDialogComponent.prototype.onSearchQuery = function (searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    };
    CaseCloneDialogComponent.prototype.onSubmit = function () {
        if (this.caseCloneForm.invalid)
            return;
        var newTitle = this.caseCloneForm.get('newTitle').value;
        var userEmail = this.caseCloneForm.get('userToClone').value;
        var selectedUser = this.foundUsers.find(function (user) { return user.email == userEmail; });
        this.matDialogRef.close(['clone', newTitle, selectedUser]);
    };
    CaseCloneDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return CaseCloneDialogComponent;
}());
export { CaseCloneDialogComponent };
export { ɵ0 };
