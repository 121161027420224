import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AnalyticsService } from 'app/core/services/planner/analytics.service';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EAnalyticsActions, GetCasesAnalyticsSuccess, GetGeneralAnalyticsSuccess, GetUsersAnalyticsSuccess } from '../actions/analytics.actions';
var AnalyticsEffects = /** @class */ (function () {
    function AnalyticsEffects(_analyticsService, _actions$, _store, _logger) {
        var _this = this;
        this._analyticsService = _analyticsService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.getGeneralAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetGeneralAnalytics), map(function (action) { return action; }), switchMap(function (params) {
            var dateInterval = params.dateInterval;
            var selectedMonth = params.selectedMonth;
            var selectedQuarter = params.selectedQuarter;
            var selectedYear = params.selectedYear;
            return _this._analyticsService.getGeneralAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap(function (result) { return of(new GetGeneralAnalyticsSuccess(result)); }));
        this.getCasesAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetCasesAnalytics), map(function (action) { return action; }), switchMap(function (params) {
            var dateInterval = params.dateInterval;
            var selectedMonth = params.selectedMonth;
            var selectedQuarter = params.selectedQuarter;
            var selectedYear = params.selectedYear;
            return _this._analyticsService.getCasesAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap(function (result) { return of(new GetCasesAnalyticsSuccess(result)); }));
        this.getUsersAnalytics$ = this._actions$.pipe(ofType(EAnalyticsActions.GetUsersAnalytics), map(function (action) { return action; }), switchMap(function (params) {
            var dateInterval = params.dateInterval;
            var selectedMonth = params.selectedMonth;
            var selectedQuarter = params.selectedQuarter;
            var selectedYear = params.selectedYear;
            return _this._analyticsService.getUsersAnalytics(dateInterval, selectedMonth, selectedQuarter, selectedYear);
        }), switchMap(function (result) { return of(new GetUsersAnalyticsSuccess(result)); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AnalyticsEffects.prototype, "getGeneralAnalytics$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AnalyticsEffects.prototype, "getCasesAnalytics$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AnalyticsEffects.prototype, "getUsersAnalytics$", void 0);
    return AnalyticsEffects;
}());
export { AnalyticsEffects };
