import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { CreatePlannerCaseCommand, SavePlannerSceneCommand, RollbackPlannerSceneCommand, UpdatePlannerCaseCommand, SavePlannerSceneVisibilityGroupsCommand, PlannerCaseRelatedDataContainer, CaseSortType, PlannerCaseShare, UpdatePlannerCaseSharesCommand, ClonePlannerCaseCommand, DeletePlannerCaseCommand, ClonePlannerCaseBatchCommand, SetPlannerCaseStatusCommand, PlannerQualitySurveyCase, SaveBundleCaseCommand, PlannerCaseBundle } from 'app/core/models';
import { AppConfig } from '../../../app-config.module';
import { NGXLogger } from 'ngx-logger';
import { EnumHelpers } from 'app/core/helpers';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
var CasesService = /** @class */ (function () {
    function CasesService(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    CasesService.prototype.getCases = function (casesRequestArgs, skip, take) {
        var _this = this;
        var modulesFilterParam = '';
        if (casesRequestArgs.modulesFilter && casesRequestArgs.modulesFilter.length > 0) {
            modulesFilterParam = '';
            for (var i = 0; i < casesRequestArgs.modulesFilter.length; i++) {
                if (i > 0) {
                    modulesFilterParam += '&';
                }
                modulesFilterParam += "Modules=" + casesRequestArgs.modulesFilter[i];
            }
        }
        var folderType = casesRequestArgs.folder;
        var sortType = EnumHelpers.getValAsString(CaseSortType, casesRequestArgs.sortType);
        var notAdmins = casesRequestArgs.notAdmins;
        var onlyUncompleted = casesRequestArgs.onlyUncompleted;
        var onlyWithGuideOrImplant = casesRequestArgs.onlyWithGuideOrImplant;
        var forQualityControl = casesRequestArgs.forQualityControl;
        var searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        var url = this.config.apiEndpoint + "/api/public/Cases/Get?" + modulesFilterParam + "&FolderType=" + folderType + "&NotAdmins=" + notAdmins + "&OnlyUncompleted=" + onlyUncompleted + "&OnlyWithGuideOrImplant=" + onlyWithGuideOrImplant + "&ForQualityControl=" + forQualityControl + "\n        &SearchPattern=" + searchPattern + "&Skip=" + skip + "&Take=" + take + "&Sort=" + sortType;
        this.logger.debug("HTTP GET cases list.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response cases list:", response);
        }), map(function (response) { return response.cases; }));
    };
    CasesService.prototype.getCase = function (caseId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Cases/GetCase?CaseId=" + caseId;
        this.logger.debug("HTTP GET case.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response case:", response);
        }), map(function (response) { return response; }));
    };
    CasesService.prototype.getCaseRelatedData = function (caseId) {
        var _this = this;
        if (!caseId)
            throw new Error('getRelatedData: caseId is null');
        var url = this.config.apiEndpoint + "/api/public/Cases/GetRelatedData?caseId=" + caseId;
        this.logger.debug("HTTP GET case related data.", "CaseId: " + caseId + ",", "url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) { return _this.logger.debug("Response case related data:", response); }), map(function (response) {
            return response;
        }));
    };
    CasesService.prototype.createCase = function (createCaseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Cases/Add";
        this.logger.debug("HTTP POST create case.", "New case: " + createCaseCommand + ",", "url: " + url);
        return this.http.post(url, createCaseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response create case:", response);
        }));
    };
    CasesService.prototype.editCase = function (updateCaseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Cases/Update";
        this.logger.debug("HTTP POST update case.", "Update case: " + updateCaseCommand + ",", "url: " + url);
        return this.http.post(url, updateCaseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response update case:", response);
        }));
    };
    CasesService.prototype.cloneCase = function (clonePlannerCaseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/CloneCase";
        this.logger.debug("HTTP POST clone case.", "Command: " + clonePlannerCaseCommand + ",", "url: " + url);
        return this.http.post(url, clonePlannerCaseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response clone case:", response);
        }));
    };
    CasesService.prototype.cloneCaseBatch = function (clonePlannerCaseBatchCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/CloneCaseBatch";
        this.logger.debug("HTTP POST clone case batch.", "Command: " + clonePlannerCaseBatchCommand + ",", "url: " + url);
        return this.http.post(url, clonePlannerCaseBatchCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response clone case batch:", response);
        }));
    };
    CasesService.prototype.getCaseShares = function (caseId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/GetCaseShares?caseId=" + caseId;
        this.logger.debug("HTTP GET case shares.", "CaseId: " + caseId + ",", "url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) { return _this.logger.debug("Response case shares:", response); }), map(function (response) { return response.shares; }));
    };
    CasesService.prototype.updateCaseShares = function (updateCaseSharesCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/UpdateCaseShares";
        this.logger.debug("HTTP POST update case shares.", "Command: " + updateCaseSharesCommand + ",", "url: " + url);
        return this.http.post(url, updateCaseSharesCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response update case shares:", response);
        }));
    };
    CasesService.prototype.deleteCase = function (deletePlannerCaseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/DeleteCase";
        this.logger.debug("HTTP POST delete case.", "Command: " + deletePlannerCaseCommand + ",", "url: " + url);
        return this.http.post(url, deletePlannerCaseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response delete case:", response);
        }));
    };
    CasesService.prototype.getCaseScenes = function (caseId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Scenes/GetCaseScenes?caseId=" + caseId;
        this.logger.debug("HTTP GET case scenes.", "CaseId: " + caseId + ",", "url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) { return _this.logger.debug("Response scenes:", response); }), map(function (response) { return response.caseScenes; }));
    };
    CasesService.prototype.getCaseScene = function (caseId, sceneId) {
        var _this = this;
        var getCaseUrl = '';
        if (!sceneId) {
            getCaseUrl = "/api/public/Scenes/GetCurrentScene?caseId=" + caseId;
        }
        else {
            getCaseUrl = "/api/public/Scenes/GetScene?caseId=" + caseId + "&sceneId=" + sceneId;
        }
        var url = "" + this.config.apiEndpoint + getCaseUrl;
        this.logger.debug("HTTP GET case scene.", "CaseId: " + caseId + ",", "sceneId: " + sceneId + ",", "url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) { return _this.logger.debug("Response scene:", response); }), map(function (response) { return response.scene; }));
    };
    CasesService.prototype.saveSceneVisibilityGroups = function (savePlannerSceneVisibilityGroupsCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/ScenesManagement/SaveSceneVisibilityGroups";
        this.logger.debug("HTTP POST save scene visibility groups.", "CaseId: " + savePlannerSceneVisibilityGroupsCommand.caseId, "sceneId: " + savePlannerSceneVisibilityGroupsCommand.sceneId + ",", "url: " + url);
        return this.http.post(url, savePlannerSceneVisibilityGroupsCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response save scene visibility groups:", response);
        }));
    };
    CasesService.prototype.saveScene = function (savePlannerCaseSceneCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/ScenesManagement/SaveScene";
        this.logger.debug("HTTP POST save scene.", "CaseId: " + savePlannerCaseSceneCommand.caseId, "scene: " + savePlannerCaseSceneCommand.scene + ",", "url: " + url);
        return this.http.post(url, savePlannerCaseSceneCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response save scene:", response);
        }));
    };
    CasesService.prototype.setPlannerCaseStatus = function (setPlannerCaseStatusCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/SetPlannerCaseStatus";
        this.logger.debug("HTTP POST set planner case status.", "CaseId: " + setPlannerCaseStatusCommand.caseId, "status: " + setPlannerCaseStatusCommand.status + ",", "url: " + url);
        return this.http.post(url, setPlannerCaseStatusCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response set planner case status:", response);
        }));
    };
    CasesService.prototype.rollbackScene = function (rollbackPlannerCaseSceneCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/ScenesManagement/RollbackScene";
        this.logger.debug("HTTP POST rollback scene.", "CaseId: " + rollbackPlannerCaseSceneCommand.caseId, "sceneId: " + rollbackPlannerCaseSceneCommand.sceneId + ",", "url: " + url);
        return this.http.post(url, rollbackPlannerCaseSceneCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response rollback scene:", response);
        }));
    };
    CasesService.prototype.getQualitySurveyCases = function (casesRequestArgs, skip, take) {
        var _this = this;
        var notAdmins = casesRequestArgs.notAdmins;
        var searchPattern = casesRequestArgs.searchPattern ? casesRequestArgs.searchPattern : '';
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/GetQualitySurveyCase?NotAdmins=" + notAdmins + "&SearchPattern=" + searchPattern + "&Skip=" + skip + "&Take=" + take + "&Sort=CreationDate";
        this.logger.debug("HTTP GET quality surveys cases list.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response quality surveys cases list:", response);
        }), map(function (response) { return response.qualitySurveys; }));
    };
    CasesService.prototype.saveCaseBundle = function (saveBundleCaseCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/SaveBundleCase";
        this.logger.debug("HTTP POST save planner case bundle.", "CaseId: " + saveBundleCaseCommand.caseId, "bundle: " + saveBundleCaseCommand.bundle + ",", "url: " + url);
        return this.http.post(url, saveBundleCaseCommand)
            .pipe(tap(function (response) {
            _this.logger.debug("Response save planner case bundle:", response);
        }));
    };
    CasesService.prototype.getPlannerCaseBundle = function (caseId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/CasesManagement/GetPlannerCaseBundle?CaseId=" + caseId;
        this.logger.debug("HTTP GET case bundle.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response case bundle:", response);
        }));
    };
    CasesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CasesService_Factory() { return new CasesService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: CasesService, providedIn: "root" });
    return CasesService;
}());
export { CasesService };
