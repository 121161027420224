import { EventEmitter, OnInit } from '@angular/core';
import { CasesAnalytics, DateInterval, DateMonth, GeneralAnalytics, Language, MedicalReferals, Speciality, UsersAnalytics } from '@appmodels';
import * as moment from 'moment';
var AnalyticsComponent = /** @class */ (function () {
    function AnalyticsComponent() {
        // widget5SelectedDay: 'today';
        this.casesWidget = {
            chartType: 'line',
            dataset: [{
                    label: 'Cases',
                    data: [],
                    fill: 'start'
                }],
            labels: [],
            colors: [
                {
                    borderColor: 'rgba(30, 136, 229, 0.87)',
                    backgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff'
                }
            ],
            options: {
                spanGaps: false,
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                tooltips: {
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                },
                layout: {
                    padding: {
                        left: 24,
                        right: 32
                    }
                },
                elements: {
                    point: {
                        radius: 4,
                        borderWidth: 2,
                        hoverRadius: 4,
                        hoverBorderWidth: 2
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: 'rgba(0,0,0,0.54)'
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                tickMarkLength: 16
                            },
                            ticks: {
                                stepSize: 1000
                            }
                        }
                    ]
                },
                plugins: {
                    filler: {
                        propagate: false
                    }
                }
            }
        };
        this.loginsWidget = {
            chartType: 'line',
            dataset: [{
                    label: 'Cases',
                    data: [],
                    fill: 'start'
                }],
            labels: [],
            colors: [
                {
                    borderColor: '#3949ab',
                    backgroundColor: '#3949ab',
                    pointBackgroundColor: '#3949ab',
                    pointHoverBackgroundColor: '#3949ab',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff'
                },
                {
                    borderColor: 'rgba(30, 136, 229, 0.87)',
                    backgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff'
                },
            ],
            options: {
                spanGaps: false,
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                tooltips: {
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                },
                layout: {
                    padding: {
                        left: 24,
                        right: 32
                    }
                },
                elements: {
                    point: {
                        radius: 4,
                        borderWidth: 2,
                        hoverRadius: 4,
                        hoverBorderWidth: 2
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: 'rgba(0,0,0,0.54)'
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                tickMarkLength: 16
                            },
                            ticks: {
                                stepSize: 1000
                            }
                        }
                    ]
                },
                plugins: {
                    filler: {
                        propagate: false
                    }
                }
            }
        };
        this.usersWidget = {
            chartType: 'line',
            dataset: [{
                    label: 'Cases',
                    data: [],
                    fill: 'start'
                }],
            labels: [],
            colors: [
                {
                    borderColor: 'rgba(30, 136, 229, 0.87)',
                    backgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointHoverBackgroundColor: 'rgba(30, 136, 229, 0.87)',
                    pointBorderColor: '#ffffff',
                    pointHoverBorderColor: '#ffffff'
                }
            ],
            options: {
                spanGaps: false,
                legend: {
                    display: false
                },
                maintainAspectRatio: false,
                tooltips: {
                    position: 'nearest',
                    mode: 'index',
                    intersect: false
                },
                layout: {
                    padding: {
                        left: 24,
                        right: 32
                    }
                },
                elements: {
                    point: {
                        radius: 4,
                        borderWidth: 2,
                        hoverRadius: 4,
                        hoverBorderWidth: 2
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                fontColor: 'rgba(0,0,0,0.54)'
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                tickMarkLength: 16
                            },
                            ticks: {
                                stepSize: 1000
                            }
                        }
                    ]
                },
                plugins: {
                    filler: {
                        propagate: false
                    }
                }
            }
        };
        this.dateIntervalEnum = DateInterval;
        this.dateMonthEnum = DateMonth;
        this.currentYear = +moment(new Date()).format('YYYY');
        this.currentMonth = +moment().format('MM');
        this.selectedYear = -1;
        this.selectedQuarter = -1;
        this.selectedMonth = -1;
        this.yearsIntervals = [];
        this.quarterIntervals = [];
        this.monthIntervals = [];
        this.medicalReferalsEnum = MedicalReferals;
        this.specialityEnum = Speciality;
        this.languageEnum = Language;
        this.selectedAnalytics = 'Cases';
        this.onCasesDateIntervalChanged = new EventEmitter();
        this._registerCustomChartJSPlugin();
    }
    Object.defineProperty(AnalyticsComponent.prototype, "casesAnalytics", {
        get: function () {
            return this._casesAnalytics;
        },
        set: function (value) {
            this._casesAnalytics = value;
            this.plotCasesChart();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnalyticsComponent.prototype, "usersAnalytics", {
        get: function () {
            return this._usersAnalytics;
        },
        set: function (value) {
            this._usersAnalytics = value;
            this.plotUsersCharts();
        },
        enumerable: true,
        configurable: true
    });
    AnalyticsComponent.prototype.ngOnInit = function () {
        this.selectedDateInterval = DateInterval.CalendarMonth;
        this.selectedYear = this.currentYear;
        this.selectedQuarter = Math.ceil(this.currentMonth / 3);
        this.selectedMonth = this.currentMonth;
        this.yearsIntervals = [this.currentYear - 2, this.currentYear - 1, this.currentYear];
        this.quarterIntervals = [1, 2, 3, 4];
        // const montsIntervals = [];
        for (var i = 0; i < 12; i++) {
            var monthIndex = i + 1;
            var monthName = moment().subtract(-i + this.currentMonth - 1, "month").startOf("month").format('MMMM');
            this.monthIntervals.push({ monthName: monthName, monthIndex: monthIndex });
        }
        //console.log( this.monthIntervals);
    };
    AnalyticsComponent.prototype.casesDateIntervalChanged = function (dateInterval) {
        this.selectedDateInterval = dateInterval;
        this.emitEvent();
    };
    AnalyticsComponent.prototype.yearIntervalChanged = function (selectingYear) {
        this.selectedYear = selectingYear;
        this.emitEvent();
        console.log(this.generalAnalytics);
    };
    AnalyticsComponent.prototype.quarterIntervalChanged = function (selectingQuarter) {
        this.selectedQuarter = selectingQuarter;
        this.emitEvent();
    };
    AnalyticsComponent.prototype.monthIntervalChanged = function (selectingMonth) {
        this.selectedMonth = selectingMonth;
        this.emitEvent();
    };
    AnalyticsComponent.prototype.selectedAnalyticsChanged = function (selectedAnalytics) {
        if (this.selectedAnalytics == selectedAnalytics)
            return;
        this.selectedAnalytics = selectedAnalytics;
        this.casesAnalytics = null;
        this.usersAnalytics = null;
        this.emitEvent();
    };
    AnalyticsComponent.prototype.emitEvent = function () {
        this.onCasesDateIntervalChanged.emit({ selectedDateInterval: this.selectedDateInterval, selectedMonth: this.selectedMonth, selectedQuarter: this.selectedQuarter, selectedYear: this.selectedYear });
    };
    AnalyticsComponent.prototype.plotCasesChart = function () {
        //console.log(this.casesAnalytics);
        if (!this.casesAnalytics)
            return;
        var widget = this.casesWidget;
        var newDataset = [{
                label: 'Cases',
                data: this.casesAnalytics.plotData.points.map(function (x) { return x.y; }),
                fill: 'start'
            }];
        widget.dataset = newDataset;
        widget.labels = this.casesAnalytics.plotData.points.map(function (x) { return x.label; });
    };
    AnalyticsComponent.prototype.plotUsersCharts = function () {
        //console.log(this.usersAnalytics);
        if (!this.usersAnalytics)
            return;
        var loginsWidget = this.loginsWidget;
        var loginsDataset = [{
                label: 'Unique logins',
                data: this.usersAnalytics.uniqLoginsPlotData.points.map(function (x) { return x.y; }),
                fill: 'start'
            },
            {
                label: 'Logins',
                data: this.usersAnalytics.loginsPlotData.points.map(function (x) { return x.y; }),
                fill: 'start'
            },];
        loginsWidget.dataset = loginsDataset;
        loginsWidget.labels = this.usersAnalytics.loginsPlotData.points.map(function (x) { return x.label; });
        var usersWidget = this.usersWidget;
        var usersDataset = [{
                label: 'New users',
                data: this.usersAnalytics.newUsersPlotData.points.map(function (x) { return x.y; }),
                fill: 'start'
            }];
        usersWidget.dataset = usersDataset;
        usersWidget.labels = this.usersAnalytics.newUsersPlotData.points.map(function (x) { return x.label; });
    };
    AnalyticsComponent.prototype._registerCustomChartJSPlugin = function () {
        window.Chart.plugins.register({
            afterDatasetsDraw: function (chart, easing) {
                // Only activate the plugin if it's made available
                // in the options
                if (!chart.options.plugins.xLabelsOnTop ||
                    (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)) {
                    return;
                }
                // To only draw at the end of animation, check for easing === 1
                var ctx = chart.ctx;
                chart.data.datasets.forEach(function (dataset, i) {
                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function (element, index) {
                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                            var fontSize = 13;
                            var fontStyle = 'normal';
                            var fontFamily = 'Roboto, Helvetica Neue, Arial';
                            ctx.font = window.Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                            // Just naively convert to string for now
                            var dataString = dataset.data[index].toString() + 'k';
                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            var padding = 15;
                            var startY = 24;
                            var position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, startY);
                            ctx.save();
                            ctx.beginPath();
                            ctx.setLineDash([5, 3]);
                            ctx.moveTo(position.x, startY + padding);
                            ctx.lineTo(position.x, position.y - padding);
                            ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                            ctx.stroke();
                            ctx.restore();
                        });
                    }
                });
            }
        });
    };
    return AnalyticsComponent;
}());
export { AnalyticsComponent };
