import { AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { tap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-logger";
var AppLogsService = /** @class */ (function () {
    function AppLogsService(config, http, logger) {
        this.config = config;
        this.http = http;
        this.logger = logger;
    }
    AppLogsService.prototype.getAppLogsGroups = function (isWithAdmins, searchPattern, sort, sortOrder, skip, take) {
        var _this = this;
        var logsSearchPattern = searchPattern ? searchPattern : '';
        var url = this.config.apiEndpoint + "/api/private/LogsManagement/GetLogsGroups?isWithAdmins=" + isWithAdmins + "&SearchPattern=" + logsSearchPattern + "&Sort=" + sort + "&SortOrder=" + sortOrder + "&Skip=" + skip + "&Take=" + take;
        this.logger.debug("HTTP GET app logs groups.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response app logs groups:", response);
        }), map(function (response) { return response.appLogsGroups; }));
    };
    AppLogsService.prototype.getAppLogsByConnection = function (connectionId) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/LogsManagement/GetLogsByConnection?AppConnectionUID=" + connectionId;
        this.logger.debug("HTTP GET app logs by connection.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response app logs by connection:", response);
        }), map(function (response) { return response.appLogs; }));
    };
    AppLogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppLogsService_Factory() { return new AppLogsService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NGXLogger)); }, token: AppLogsService, providedIn: "root" });
    return AppLogsService;
}());
export { AppLogsService };
