import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { distinctUntilChanged, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var S3StorageService = /** @class */ (function () {
    function S3StorageService(http) {
        this.http = http;
    }
    S3StorageService.prototype.uploadToBlobStorage = function (file, preSignedUrl, fileBlobName) {
        var headers = new HttpHeaders().set('Content-type', '');
        return this.http.put(preSignedUrl, file, { reportProgress: true, observe: "events", headers: headers })
            .pipe(map(function (event) {
            if (event.type === HttpEventType.UploadProgress) {
                return event.loaded;
            }
            if (event.type === HttpEventType.Response) {
                return file.size;
            }
        }), distinctUntilChanged());
    };
    S3StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function S3StorageService_Factory() { return new S3StorageService(i0.ɵɵinject(i1.HttpClient)); }, token: S3StorageService, providedIn: "root" });
    return S3StorageService;
}());
export { S3StorageService };
