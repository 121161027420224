import { AfterViewInit, OnDestroy, OnInit, EventEmitter, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { PlannerMessage, PlannerCase, IUser, FileType, PlannerFile } from '@appmodels';
import { MatDialog } from '@angular/material';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { Lightbox } from 'ngx-lightbox';
import { DownloadDialogComponent } from 'app/core/shared';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
//import { ChatService } from 'app/pages/chat/chat.service';
var ChatViewComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    function ChatViewComponent(_router, _cdr, _filesService, _lightbox, _downloadFileDialog, logger
    // private _chatService: ChatService
    ) {
        this._router = _router;
        this._cdr = _cdr;
        this._filesService = _filesService;
        this._lightbox = _lightbox;
        this._downloadFileDialog = _downloadFileDialog;
        this.logger = logger;
        this.isRequestInProgress = false;
        this.fileTypeEnum = FileType;
        this._hasFocus = true;
        this.selectedFileImg = false;
        this._cachedUrls = {};
        this._getUrlSubs = {};
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.onNewMessage = new EventEmitter();
        this.onMessagesReaded = new EventEmitter();
        this.onGetMoreMessages = new EventEmitter();
    }
    Object.defineProperty(ChatViewComponent.prototype, "messages", {
        get: function () {
            return this._messages;
        },
        set: function (value) {
            this.isRequestInProgress = false;
            if (value.length <= 20) {
                this.readyToReply();
            }
            this.messageReaded();
            this._messages = value;
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ChatViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedChat = this.selectedCase;
        this.user = this.currentUser;
        this.contact = {};
        setInterval(function () {
            var currentHasFocus = document.hasFocus();
            if (!_this._hasFocus && currentHasFocus) {
                _this.scrollToBottom();
            }
            _this._hasFocus = currentHasFocus;
        }, 300);
    };
    /**
     * After view init
     */
    ChatViewComponent.prototype.ngAfterViewInit = function () {
        this.replyInput = this.replyInputField.first.nativeElement;
        //console.log(this.replyIsMessageEmailField);
        this.replyIsMessageEmailField.first.checked = true;
        //  this.replyIsMessageEmail = this.replyIsMessageEmailField.first.checked;
        // //console.log('asdasda');
        this.readyToReply();
    };
    /**
     * On destroy
     */
    ChatViewComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    ChatViewComponent.prototype.shouldShowContactAvatar = function (message, i) {
        return (message.userNameFrom !== this.user.userName &&
            ((this.messages[i + 1] && this.messages[i + 1].userNameFrom === this.user.userName) || !this.messages[i + 1]));
    };
    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    ChatViewComponent.prototype.isFirstMessageOfGroup = function (message, i) {
        return (i === 0 || this.messages[i - 1] && this.messages[i - 1].userNameFrom !== message.userNameFrom);
    };
    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    ChatViewComponent.prototype.isLastMessageOfGroup = function (message, i) {
        return (i === this.messages.length - 1 || this.messages[i + 1] && this.messages[i + 1].userNameFrom !== message.userNameFrom);
    };
    /**
     * Select contact
     */
    ChatViewComponent.prototype.goToCase = function (caseId) {
        this._router.navigate(['/cases/all/' + caseId]);
        //this._chatService.selectContact(this.contact);
    };
    /**
     * Ready to reply
     */
    ChatViewComponent.prototype.readyToReply = function () {
        var _this = this;
        setTimeout(function () {
            _this.focusReplyInput();
            _this.scrollToBottom();
        });
    };
    ChatViewComponent.prototype.messageReaded = function () {
        var _this = this;
        var dispatchFunc = function () { return _this.onMessagesReaded.emit(); };
        clearTimeout(this._caseMessagesReadedTimer);
        this._caseMessagesReadedTimer = setTimeout(dispatchFunc, 2000);
    };
    /**
     * Focus to the reply input
     */
    ChatViewComponent.prototype.focusReplyInput = function () {
        var _this = this;
        setTimeout(function () {
            _this.replyInput.focus();
        });
    };
    /**
     * Scroll to the bottom
     *
     * @param {number} speed
     */
    ChatViewComponent.prototype.scrollToBottom = function (speed) {
        var _this = this;
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();
            setTimeout(function () {
                _this.directiveScroll.scrollToBottom(0, speed);
            });
        }
    };
    ChatViewComponent.prototype.onChatScroll = function () {
        console.log(123);
        this.onGetMoreMessages.emit();
    };
    /**
     * Reply
     */
    ChatViewComponent.prototype.reply = function (event) {
        var _this = this;
        event.preventDefault();
        if (!this.replyForm.form.value.message && !this.selectedFile) {
            return;
        }
        this.isRequestInProgress = true;
        var message = this.replyForm.form.value.message ? this.replyForm.form.value.message : '';
        var sendToId = this.selectedCase.ownerId;
        var isMessageEmail = this.replyIsMessageEmailField.first.checked; //this.replyForm.form.value.isMessageEmail;
        // console.log(isMessageEmail);
        if (this.selectedFile) {
            this.uploadFile(this.selectedFile).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function (messageFile) {
                if (messageFile) {
                    //console.log(fileResponse);
                    //const attachedFileId = messageFile.id;
                    _this.onNewMessage.emit({ message: message, sendToId: sendToId, isMessageEmail: isMessageEmail, messageFile: messageFile });
                    _this.replyForm.reset();
                    _this.removeAttachment();
                }
            });
        }
        else {
            this.onNewMessage.emit({ message: message, sendToId: sendToId, isMessageEmail: isMessageEmail });
            this.replyForm.reset();
        }
    };
    ChatViewComponent.prototype.filesSelected = function (files) {
        //  console.log(files.length);
        var _this = this;
        if (files.length <= 0)
            return;
        this.selectedFile = files[files.length - 1];
        this.selectedFileImg = this.isFileImage(this.selectedFile);
        if (this.selectedFileImg) {
            var reader = new FileReader();
            reader.readAsDataURL(this.selectedFile);
            var observable = new Observable(function (observer) {
                // if success
                reader.onload = (function (_event) {
                    observer.next(reader.result);
                    observer.complete();
                });
            });
            observable
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function (value) {
                _this.attachedImgUrl = value;
                _this._cdr.detectChanges();
                _this.scrollToBottom(1);
            });
        }
    };
    ChatViewComponent.prototype.removeAttachment = function () {
        this.selectedFile = null;
        this.ngfModuleFiles = [];
        this.attachedImgUrl = null;
    };
    ChatViewComponent.prototype.uploadFile = function (file) {
        // const fileGroup = this.filesEditForm.controls[filesIndex.groupName] as FormGroup;
        // const file = this.selectedFile;
        var title = file.name;
        var fileType = this.isFileImage(file) ? FileType.PICTURE : FileType.DOC;
        var additionalInfoObj = {};
        var needToZip = false;
        var additionalInfo = JSON.stringify(additionalInfoObj);
        return this._filesService.uploadFile(file, this.selectedCase.id, title, fileType, additionalInfo, needToZip).pipe(map(function (fileResponse) {
            if (fileResponse.status == 'done') {
                var messageFile = new PlannerFile();
                messageFile.id = fileResponse.fileId;
                messageFile.title = title;
                messageFile.fileName = title;
                messageFile.fileType = fileType;
                messageFile.additionalInfo = additionalInfo;
                messageFile.fileSize = file.size;
                return messageFile;
            }
            else {
                return null;
            }
        }), catchError(function (err) {
            throw err;
        }));
    };
    ChatViewComponent.prototype.downloadFile = function (plannerFile) {
        var _this = this;
        var fileId = plannerFile.id;
        var fileName = plannerFile.fileName;
        var sub = this._filesService.downloadFile(this.selectedCase.id, fileId, fileName)
            .pipe(takeUntil(this._unsubscribeAll));
        var dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.logger.info('The dialog was closed');
        });
    };
    ChatViewComponent.prototype.getFileUrl = function (plannerFile) {
        var fileId = plannerFile.id;
        var key = fileId;
        return this._cachedUrls[key];
    };
    ChatViewComponent.prototype.getFileUrlAsync = function (plannerFile) {
        var _this = this;
        var fileId = plannerFile.id;
        var fileName = plannerFile.fileName;
        var key = fileId;
        if (!this._cachedUrls[key]) {
            if (!this._getUrlSubs[key]) {
                var getUrlSub = this._filesService.getFileDownloadUrl(this.selectedCase.id, fileId, fileName)
                    .pipe(takeUntil(this._unsubscribeAll), tap(function (sasLink) {
                    _this._cachedUrls[key] = sasLink;
                }));
                this._getUrlSubs[key] = getUrlSub;
            }
            return this._getUrlSubs[key];
        }
        else {
            return of(this._cachedUrls[key]);
        }
    };
    ChatViewComponent.prototype.openImageLightBox = function (plannerFile) {
        var fileId = plannerFile.id;
        var fileName = plannerFile.fileName;
        var key = fileId;
        var url = this._cachedUrls[key];
        var album = {
            src: url,
            caption: fileName,
            thumb: ''
        };
        this._lightbox.open([album], 0, { centerVertically: true, showZoom: true, showRotate: true, showDownloadButton: true });
    };
    ChatViewComponent.prototype.isFileImage = function (file) {
        var dotExt = file.name.lastIndexOf(".");
        if (dotExt < 0)
            return false;
        var ext = file.name.slice(dotExt + 1, file.name.length).toLowerCase();
        if (ext == "jpeg" || ext == "jpg" || ext == "png" || ext == "bmp")
            return true;
        return false;
    };
    return ChatViewComponent;
}());
export { ChatViewComponent };
