import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { AuthenticationService, CasesService } from '@appservices';
import { Store, select } from '@ngrx/store';
import { GetCases, ECasesActions, GetCasesSuccess, SelectCase, SelectCaseSuccess, GetSelectedCaseScenes, GetSelectedCaseScenesSuccess, SelectSceneSuccess, SelectScene, CreateCaseSuccess, SaveScene, SaveSceneSuccess, CloneCaseSuccess, DeleteCaseSuccess, DeselectCase, DeselectCaseSuccess, SetCasesFolderSuccess, RollbackSceneSuccess, SetCasesSearchPatternSuccess, EditCaseSuccess, SaveSceneVisibilityGroupsSuccess, CaseMessagesReadedSuccess, GetSelectedCaseSharesSuccess, GetSelectedCaseShares, UpdateCaseSharesSuccess, GetCaseSuccess, SetModulesTypeSuccess, CloneCaseBatchSuccess, SetCasesFilterParamsSuccess, SetCaseStatusSuccess, SetQCFilterSuccess, GetCaseBundlePdf as GetCaseBundlePdf, GetCaseBundleSuccess, SaveCaseBundleSuccess, } from '../actions/cases.actions';
import { switchMap, map, withLatestFrom, tap, take, filter, mergeMap, catchError, repeat, delay } from 'rxjs/operators';
import { of, forkJoin, throwError } from 'rxjs';
import { PlannerCase, PlannerScene, SavePlannerSceneCommand, CaseSortType, SetPlannerCaseStatusCommand } from '@appmodels';
import { selectCasesList, selectCasesRequestArgs, selectSelectedCase, selectSelectedScene } from '../selectors/cases.selector';
import { NewNotification } from '../actions/notifications.actions';
import { NGXLogger } from 'ngx-logger';
import { VoidAction } from "../actions/app.actions";
import { SettingsService } from "app/core/services/settings.service";
import { LongRunningTasksService } from "app/core/services/planner/longRunningTasks.service";
import { DocumentsService } from "app/core/services/planner/documents.service";
var CasesEffects = /** @class */ (function () {
    function CasesEffects(_settingsService, _casesService, _documentsService, _longRunningTasksService, _authenticationService, _actions$, _store, _logger) {
        var _this = this;
        this._settingsService = _settingsService;
        this._casesService = _casesService;
        this._documentsService = _documentsService;
        this._longRunningTasksService = _longRunningTasksService;
        this._authenticationService = _authenticationService;
        this._actions$ = _actions$;
        this._store = _store;
        this._logger = _logger;
        this.setModulesType$ = this._actions$.pipe(ofType(ECasesActions.SetModulesType), map(function (action) { return action.moduleType; }), withLatestFrom(this._store.select(selectCasesRequestArgs)), map(function (_a) {
            var moduleType = _a[0], casesRequestArgs = _a[1];
            var modulesFilter = casesRequestArgs.modulesFilter.slice();
            var moduleTypeIndex = modulesFilter.indexOf(moduleType);
            if (moduleTypeIndex < 0) {
                modulesFilter.push(moduleType);
            }
            else {
                modulesFilter.splice(moduleTypeIndex, 1);
            }
            _this._settingsService.setModulesFilters(modulesFilter);
            return modulesFilter;
        }), switchMap(function (modulesFilter) {
            return [
                new SetModulesTypeSuccess(modulesFilter),
                new GetCases(),
            ];
        }));
        this.setQCFilter$ = this._actions$.pipe(ofType(ECasesActions.SetQCFilter), withLatestFrom(this._store.select(selectCasesRequestArgs)), map(function (_a) {
            var _ = _a[0], casesRequestArgs = _a[1];
            return !casesRequestArgs.forQualityControl;
        }), switchMap(function (isForQualityControl) {
            return [
                new SetQCFilterSuccess(isForQualityControl),
                new GetCases(),
            ];
        }));
        this.setFolder$ = this._actions$.pipe(ofType(ECasesActions.SetCasesFolder), map(function (action) {
            return { folderType: action.folderType, sortType: (action.sortType ? action.sortType : CaseSortType.CreationDate) };
        }), switchMap(function (sortSettings) {
            return [
                new SetCasesFolderSuccess(sortSettings.folderType, sortSettings.sortType),
                new GetCases(),
            ];
        }));
        this.setSearchPattern$ = this._actions$.pipe(ofType(ECasesActions.SetCasesSearchPattern), map(function (action) { return action.payload; }), switchMap(function (searchPattern) {
            return [
                new SetCasesSearchPatternSuccess(searchPattern),
                new GetCases(),
            ];
        }));
        this.setCasesFilterParams$ = this._actions$.pipe(ofType(ECasesActions.SetCasesFilterParams), switchMap(function (searchParams) {
            return [
                new SetCasesFilterParamsSuccess(searchParams.folder, searchParams.modulesFilter, searchParams.notAdmins, searchParams.onlyUncompleted, searchParams.onlyWithGuideOrImplant, searchParams.searchPattern),
                new GetCases(),
            ];
        }));
        this.getCases$ = this._actions$.pipe(ofType(ECasesActions.GetCases), map(function (action) { return action.isReload; }), 
        //map
        switchMap(function (isReload) {
            _this._logger.info("GetCases, is reload: " + isReload);
            if (isReload) {
                return of([]).pipe(map(function () {
                    return { existedCasesCount: 0, isReload: isReload };
                }));
            }
            else {
                return _this._store.pipe(select(selectCasesList), take(1), map(function (cases) {
                    return { existedCasesCount: cases ? cases.length : 0, isReload: isReload };
                }));
            }
        }), withLatestFrom(this._store.select(selectCasesRequestArgs)), switchMap(function (_a) {
            var existedCases = _a[0], casesRequestArgs = _a[1];
            var skip = existedCases.isReload ? 0 : existedCases.existedCasesCount; //? existedCases.length : 0;
            var take = 20;
            return _this._casesService.getCases(casesRequestArgs, skip, take).pipe(map(function (cases) { return { cases: cases, isReload: existedCases.isReload }; }));
        }), switchMap(function (casesResult) { return of(new GetCasesSuccess(casesResult.cases, casesResult.isReload)); }));
        this.getCase$ = this._actions$.pipe(ofType(ECasesActions.GetCase), map(function (action) { return action.payload; }), 
        //map
        switchMap(function (caseId) {
            return _this._casesService.getCase(caseId);
        }), mergeMap(function (plannerCase) {
            return [new GetCaseSuccess(plannerCase)];
        }));
        this.selectCase$ = this._actions$.pipe(ofType(ECasesActions.SelectCase), map(function (action) { return action.payload; }), switchMap(function (caseId) {
            return forkJoin(_this._casesService.getCase(caseId), _this._casesService.getCaseRelatedData(caseId), _this._store.select(selectCasesList).pipe(filter(function (cases) {
                if (!cases)
                    return false;
                if (!cases.find(function (x) { return x.id == caseId; }))
                    return false;
                return true;
            }), take(1))).pipe(map(function (_a) {
                //var existedCaseInList = cases.filter(x => x.id == caseId)[0]
                var response1 = _a[0], response2 = _a[1], cases = _a[2];
                var selectedCase = response1;
                var caseRelatedDataContainer = response2;
                selectedCase.details = caseRelatedDataContainer.caseRelatedData;
                return selectedCase;
            }));
        }), switchMap(function (selectedCase) { return of(new SelectCaseSuccess(selectedCase)); }));
        this.deselectCase$ = this._actions$.pipe(ofType(ECasesActions.DeselectCase), mergeMap(function (result) {
            return [
                new DeselectCaseSuccess()
            ];
        }));
        this.createCase$ = this._actions$.pipe(ofType(ECasesActions.CreateCase), map(function (action) { return action.payload; }), switchMap(function (caseCreateCommand) { return _this._casesService.createCase(caseCreateCommand); }), mergeMap(function (caseId) {
            return [
                new CreateCaseSuccess(caseId),
                new GetCases(),
                new SelectCase(caseId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: null }),
                new NewNotification({ message: 'Case created!', type: 'CaseCreated', action: 'Ok' })
            ];
        }));
        this.editCase$ = this._actions$.pipe(ofType(ECasesActions.EditCase), map(function (action) { return action.payload; }), switchMap(function (caseUpdateCommand) { return _this._casesService.editCase(caseUpdateCommand); }), mergeMap(function (caseId) {
            return [
                new EditCaseSuccess(caseId),
                new GetCases(),
                new SelectCase(caseId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: null }),
                new NewNotification({ type: 'CaseEdited', message: 'Case edited!', action: 'Ok' })
            ];
        }));
        this.cloneCase$ = this._actions$.pipe(ofType(ECasesActions.CloneCase), map(function (action) { return action.payload; }), switchMap(function (cloneCaseCommand) { return _this._casesService.cloneCase(cloneCaseCommand); }), mergeMap(function (result) {
            return [
                new CloneCaseSuccess(),
                new GetCases(true),
                new NewNotification({ type: 'CaseClonned', message: 'Case clonned!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            new NewNotification({ type: 'CaseClonned', message: 'Case clone error!', action: 'Error' });
            return throwError(err);
        }));
        this.cloneCaseBatch$ = this._actions$.pipe(ofType(ECasesActions.CloneCaseBatch), map(function (action) { return action.payload; }), switchMap(function (cloneCaseBatchCommand) { return _this._casesService.cloneCaseBatch(cloneCaseBatchCommand); }), mergeMap(function (longRunnigTaskId) {
            return _this._longRunningTasksService.getLongRunningTaskInfo(longRunnigTaskId).pipe(delay(200), repeat(), tap(function (info) { return _this._store.dispatch(new CloneCaseBatchSuccess(info)); }), filter(function (response) { return response && response.isCompleted; }), take(1));
        }), mergeMap(function (result) {
            return [
                new CloneCaseBatchSuccess(result),
                new NewNotification({ type: 'CaseClonned', message: 'Case clonned!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            new NewNotification({ type: 'CaseClonned', message: 'Case clone batch error!', action: 'Error' });
            return throwError(err);
        }));
        this.getSelectedCaseShares$ = this._actions$.pipe(ofType(ECasesActions.GetSelectedCaseShares), map(function (action) { return action.payload; }), switchMap(function (caseId) { return _this._casesService.getCaseShares(caseId); }), mergeMap(function (result) {
            return [
                new GetSelectedCaseSharesSuccess(result)
            ];
        }));
        this.updateCaseShares$ = this._actions$.pipe(ofType(ECasesActions.UpdateCaseShares), map(function (action) { return action.payload; }), switchMap(function (updateCaseSharesCommand) { return _this._casesService.updateCaseShares(updateCaseSharesCommand).pipe(switchMap(function (res) { return of(updateCaseSharesCommand.caseId); })); }), mergeMap(function (updatesSharesCaseId) {
            return [
                new UpdateCaseSharesSuccess(),
                new GetSelectedCaseShares(updatesSharesCaseId),
                //  new GetCases(true),
                new NewNotification({ type: 'CaseShared', message: 'Case shared!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            new NewNotification({ type: 'CaseShared', message: 'Case share error!', action: 'Error' });
            return throwError(err);
        }));
        this.deleteCase$ = this._actions$.pipe(ofType(ECasesActions.DeleteCase), map(function (action) { return action.payload; }), switchMap(function (deleteCaseCommand) { return _this._casesService.deleteCase(deleteCaseCommand); }), mergeMap(function (result) {
            return [
                new DeleteCaseSuccess(),
                new GetCases(true),
                new DeselectCase(),
                new NewNotification({ type: 'CaseDeleted', message: 'Case deleted!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            var errNotification = new NewNotification({ type: 'CaseDeleted', message: 'Case delete error!', action: 'Error' });
            _this._store.dispatch(errNotification);
            return throwError(err);
        }));
        this.getSelectedCaseScenes$ = this._actions$.pipe(ofType(ECasesActions.GetSelectedCaseScenes), map(function (action) { return action.payload; }), switchMap(function (caseId) {
            return forkJoin(_this._casesService.getCaseScenes(caseId), _this._store.select(selectCasesList).pipe(filter(function (cases) {
                if (!cases)
                    return false;
                if (!cases.find(function (x) { return x.id == caseId; }))
                    return false;
                return true;
            }), take(1))).pipe(map(function (_a) {
                var response = _a[0], cases = _a[1];
                return response;
            }));
        }), switchMap(function (plannerCaseScenes) { return of(new GetSelectedCaseScenesSuccess(plannerCaseScenes)); }));
        this.selectSceneSelectedCase$ = this._actions$.pipe(ofType(ECasesActions.SelectScene), map(function (action) { return action.payload; }), switchMap(function (args) {
            return forkJoin(_this._casesService.getCaseScene(args.caseId, args.sceneId), _this._store.select(selectCasesList).pipe(filter(function (cases) {
                if (!cases)
                    return false;
                if (!cases.find(function (x) { return x.id == args.caseId; }))
                    return false;
                return true;
            }), take(1))).pipe(map(function (_a) {
                var response = _a[0], cases = _a[1];
                return response;
            }));
        }), switchMap(function (plannerCaseScene) { return of(new SelectSceneSuccess(plannerCaseScene)); }));
        this.addSceneObjects$ = this._actions$.pipe(ofType(ECasesActions.AddSceneObjects), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedScene), this._store.select(selectSelectedCase)), switchMap(function (_a) {
            var sceneObjects = _a[0], selectedScene = _a[1], selectedCase = _a[2];
            sceneObjects.forEach(function (sceneObject) {
                selectedScene.sceneObjects.push(sceneObject);
            });
            var saveSceneCommand = new SavePlannerSceneCommand();
            saveSceneCommand.caseId = selectedCase.id;
            saveSceneCommand.scene = selectedScene;
            return [
                //new AddSceneObjectsSuccess(),
                new SaveScene(saveSceneCommand)
            ];
        }));
        this.saveScene$ = this._actions$.pipe(ofType(ECasesActions.SaveScene), map(function (action) { return action.payload; }), switchMap(function (saveSceneCommand) { return _this._casesService.saveScene(saveSceneCommand).pipe(map(function (saveResult) { return [saveSceneCommand.caseId, saveResult]; })); }), mergeMap(function (_a) {
            var caseId = _a[0], saveResult = _a[1];
            var saveResultModel = saveResult;
            return [
                new SaveSceneSuccess(saveResultModel),
                new SelectCase(caseId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: saveResultModel.sceneNewId }),
                new NewNotification({ type: 'SceneSaved', message: 'Scene saved!', action: 'Ok' })
            ];
        }));
        this.rollbackScene$ = this._actions$.pipe(ofType(ECasesActions.RollbackScene), map(function (action) { return action.payload; }), switchMap(function (rollbackSceneCommand) { return _this._casesService.rollbackScene(rollbackSceneCommand).pipe(map(function (sceneId) { return [rollbackSceneCommand.caseId, sceneId]; })); }), mergeMap(function (_a) {
            var caseId = _a[0], sceneId = _a[1];
            return [
                new RollbackSceneSuccess(sceneId),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: sceneId }),
                new NewNotification({ type: 'SceneRollbacked', message: 'Scene rollbacked!', action: 'Ok' })
            ];
        }));
        this.saveSceneVisibilityGroups$ = this._actions$.pipe(ofType(ECasesActions.SaveSceneVisibilityGroups), map(function (action) { return action.payload; }), switchMap(function (saveSceneVisibilityGroupsCommand) { return _this._casesService.saveSceneVisibilityGroups(saveSceneVisibilityGroupsCommand).pipe(map(function (result) { return [saveSceneVisibilityGroupsCommand.caseId, saveSceneVisibilityGroupsCommand.sceneId]; })); }), mergeMap(function (_a) {
            var caseId = _a[0], sceneId = _a[1];
            return [
                new SaveSceneVisibilityGroupsSuccess(true),
                new GetSelectedCaseScenes(caseId),
                new SelectScene({ caseId: caseId, sceneId: sceneId }),
                new NewNotification({ type: 'SceneVisibilityGroupsSaved', message: 'Scene visibility groups saved!', action: 'Ok' })
            ];
        }), catchError(function (err) {
            var errNotification = new NewNotification({ type: 'SceneVisibilityGroupsSaved', message: 'Scene visibility groups save error!', action: 'Error' });
            _this._store.dispatch(errNotification);
            return throwError(err);
        }));
        this.setCaseStatus$ = this._actions$.pipe(ofType(ECasesActions.SetCaseStatus), switchMap(function (saveSceneArgs) {
            var setPlannerCaseStatusCommand = new SetPlannerCaseStatusCommand();
            setPlannerCaseStatusCommand.caseId = saveSceneArgs.caseId;
            setPlannerCaseStatusCommand.status = saveSceneArgs.status;
            return _this._casesService.setPlannerCaseStatus(setPlannerCaseStatusCommand).pipe(map(function (saveResult) { return saveSceneArgs; }));
        }), mergeMap(function (saveSceneArgs) {
            return [
                new SetCaseStatusSuccess(saveSceneArgs.caseId, saveSceneArgs.status, saveSceneArgs.isRemoveCaseFromList),
                new NewNotification({ type: 'CaseStatusSet', message: 'Case status set!', action: 'Ok' })
            ];
        }));
        this.getCaseBundle$ = this._actions$.pipe(ofType(ECasesActions.GetCaseBundle), map(function (action) { return action.payload; }), switchMap(function (caseId) {
            return _this._casesService.getPlannerCaseBundle(caseId);
        }), mergeMap(function (result) {
            return [
                new GetCaseBundleSuccess(result)
            ];
        }));
        this.saveCaseBundle$ = this._actions$.pipe(ofType(ECasesActions.SaveCaseBundle), map(function (action) { return action.payload; }), switchMap(function (saveCaseBundleCommand) {
            return _this._casesService.saveCaseBundle(saveCaseBundleCommand).pipe(map(function (result) {
                return saveCaseBundleCommand.bundle;
            }));
        }), mergeMap(function (bundle) {
            return [
                new GetCaseBundlePdf(bundle),
                new SaveCaseBundleSuccess(bundle),
                // new SetCaseStatusSuccess(saveSceneArgs.caseId, saveSceneArgs.status, saveSceneArgs.isRemoveCaseFromList),
                new NewNotification({ type: 'BundleSaved', message: 'Bundle saved!', action: 'Ok' })
            ];
        }));
        this.getCaseBundlePdf$ = this._actions$.pipe(ofType(ECasesActions.GetCaseBundlePdf), map(function (action) { return action.payload; }), switchMap(function (bundle) {
            var bundleObj = {};
            bundleObj.bundle = JSON.parse(bundle);
            console.log(bundleObj);
            return _this._documentsService.getBundlePdf(bundleObj).pipe(map(function (result) {
            }));
        }), mergeMap(function (_) {
            return [
                new NewNotification({ type: 'BundlePdf', message: 'Bundle pdf ready!', action: 'Ok' })
            ];
        }));
        this.caseMessagesReaded$ = this._actions$.pipe(ofType(ECasesActions.CaseMessagesReaded), map(function (action) { return action; }), withLatestFrom(this._store.select(selectSelectedCase)), map(function (_a) {
            var x = _a[0], selectedCase = _a[1];
            return selectedCase.id;
        }), mergeMap(function (selectedCaseId) {
            return [
                new CaseMessagesReadedSuccess(selectedCaseId),
            ];
        }));
        this.caseSceneSavedFromServer$ = this._actions$.pipe(ofType(ECasesActions.CaseSceneSavedFromServer), map(function (action) { return action.payload; }), withLatestFrom(this._store.select(selectSelectedCase)), mergeMap(function (_a) {
            var caseSceneSavedNotification = _a[0], selectedCase = _a[1];
            var isFromAnotherConnectionApp = _this._authenticationService.appConnectionUid != caseSceneSavedNotification.senderAppConnectionUid;
            var res = [];
            res.push(new VoidAction());
            if (isFromAnotherConnectionApp) {
                res.push(new NewNotification({ type: 'CaseSceneSaved', message: "Scene saved! " + caseSceneSavedNotification.info.userInitiator + ": " + caseSceneSavedNotification.info.caseTitle, action: 'Ok' }));
                if (selectedCase && selectedCase.id == caseSceneSavedNotification.caseId) {
                    var caseId = caseSceneSavedNotification.caseId;
                    res.push(new SelectCase(caseId));
                    res.push(new GetSelectedCaseScenes(caseId));
                    res.push(new SelectScene({ caseId: caseId, sceneId: null }));
                }
            }
            return res;
        }));
    }
    CasesEffects.prototype.ngrxOnInitEffects = function () {
        var modulesFilters = this._settingsService.getModulesFilters();
        return new SetModulesTypeSuccess(modulesFilters);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setModulesType$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setQCFilter$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setFolder$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setSearchPattern$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setCasesFilterParams$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getCases$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "selectCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "deselectCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "createCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "editCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "cloneCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "cloneCaseBatch$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getSelectedCaseShares$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "updateCaseShares$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "deleteCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getSelectedCaseScenes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "selectSceneSelectedCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "addSceneObjects$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "saveScene$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "rollbackScene$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "saveSceneVisibilityGroups$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "setCaseStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getCaseBundle$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "saveCaseBundle$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "getCaseBundlePdf$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "caseMessagesReaded$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CasesEffects.prototype, "caseSceneSavedFromServer$", void 0);
    return CasesEffects;
}());
export { CasesEffects };
