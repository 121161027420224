import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { take, filter } from 'rxjs/operators';
import { ResetUsersFilter } from '../store/actions/users.actions';
import { selectQualitySurveyCasesList } from '../store/selectors/qualitySurveyCases.selector';
import { SetQualitySurveysCasesFilterParams } from '../store/actions/qualitySurveyCases.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
var CaseQualitySurveysResolver = /** @class */ (function () {
    function CaseQualitySurveysResolver(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    CaseQualitySurveysResolver.prototype.resolve = function (route, state) {
        var routeParams = route.params;
        this.logger.info('Case quality surveys route resolver: ', route.params);
        var obsCollection = [];
        obsCollection.push(this._store.select(selectQualitySurveyCasesList).pipe(filter(function (cases) { return cases != null; }), take(1)));
        obsCollection.push(of(this._store.dispatch(new SetQualitySurveysCasesFilterParams(true, ''))));
        this._store.dispatch(new ResetUsersFilter());
        return forkJoin(obsCollection);
    };
    CaseQualitySurveysResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CaseQualitySurveysResolver_Factory() { return new CaseQualitySurveysResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: CaseQualitySurveysResolver, providedIn: "root" });
    return CaseQualitySurveysResolver;
}());
export { CaseQualitySurveysResolver };
