import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "ngx-logger";
var LanguageService = /** @class */ (function () {
    function LanguageService(translateService, logger) {
        this.translateService = translateService;
        this.logger = logger;
        this._storageLangSettingName = 'userLang';
        this._defaultLang = 'en';
        this._supportedLangs = ['en', 'ru'];
        var lang = localStorage.getItem(this._storageLangSettingName);
        if (!lang) {
            lang = this.getUserLang();
        }
        this.setLanguage(lang);
        logger.log("user lang: " + this._selectedLang);
    }
    Object.defineProperty(LanguageService.prototype, "selectedLang", {
        get: function () {
            return this._selectedLang;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageService.prototype, "supportedLangs", {
        get: function () {
            return this._supportedLangs;
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.setLanguage = function (lang) {
        lang = this.selectSupportedLangs(lang);
        if (this._selectedLang != lang) {
            this._selectedLang = lang;
            localStorage.setItem(this._storageLangSettingName, lang);
            this.translateService.use(this._selectedLang);
        }
    };
    LanguageService.prototype.getUserLang = function () {
        var userLang = this.getBrowserLang();
        userLang = this.selectSupportedLangs(userLang);
        return userLang;
    };
    LanguageService.prototype.getBrowserLang = function () {
        var userLang = navigator.language || window.navigator.language;
        userLang = this.formatLangString(userLang);
        return userLang;
    };
    LanguageService.prototype.selectSupportedLangs = function (lang) {
        if (this._supportedLangs.includes(lang))
            return lang;
        return this._defaultLang;
    };
    LanguageService.prototype.formatLangString = function (lang) {
        if (!lang)
            return lang;
        // const hyphenIndex = lang.indexOf('-');
        // if(hyphenIndex >= 0)
        // {
        //     lang = lang.substr(0, lang.length - hyphenIndex - 1);
        // }
        lang = lang.substr(0, 2);
        return lang;
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.NGXLogger)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
