import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '@appservices';
import { throwError } from 'rxjs';
import { AppConfig } from 'app/app-config.module';
import { NGXLogger } from 'ngx-logger';
import { tap, map, catchError } from 'rxjs/operators';
import { SortOrder } from 'app/core/models/planner/enums/commonEnums';
import { ConnectionInfo, PlannerUsersOrganization, RevokeWebLinkTokenCommand } from '@appmodels';
import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.module";
import * as i2 from "@angular/common/http";
import * as i3 from "../authentication.service";
import * as i4 from "ngx-logger";
var UsersService = /** @class */ (function () {
    function UsersService(config, http, authenticationService, logger) {
        this.config = config;
        this.http = http;
        this.authenticationService = authenticationService;
        this.logger = logger;
    }
    UsersService.prototype.getCurrentUser = function () {
        console.log(this.authenticationService.currentUser);
        return this.authenticationService.currentUser;
    };
    UsersService.prototype.getPlannerUsersOrganization = function (organizationTitle) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Users/GetPlannerUsersOrganization?title=" + organizationTitle;
        this.logger.debug("HTTP GET GetPlannerUsersOrganization", "url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) { return _this.logger.debug("Response get planner users organization:", response); }), catchError(function (err) {
            _this.logger.info(err.status);
            return throwError(err);
        }));
    };
    UsersService.prototype.isEmailAvailable = function (email) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Users/IsEmailAvailable";
        this.logger.debug("HTTP POST IsEmailAvailable", "url: " + url);
        return this.http.post(url, { Email: email })
            .pipe(tap(function (response) { return _this.logger.debug("Response is email availbale:", response); }), catchError(function (err) {
            _this.logger.info(err.status);
            return throwError(err);
        }));
    };
    UsersService.prototype.register = function (registerCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/public/Users/Register";
        this.logger.debug("HTTP POST register user", "url: " + url, registerCommand);
        return this.http.post(url, registerCommand)
            .pipe(tap(function (response) { return _this.logger.debug("Response register user:", response); }), catchError(function (err) {
            _this.logger.error(err);
            _this.logger.error(err.status);
            return throwError(err);
        }));
    };
    UsersService.prototype.getUsers = function (usersFilter) {
        var _this = this;
        var filterQuery = this.createFilterUrlParams(usersFilter);
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/GetUsers?" + filterQuery + "&Skip=0&Take=1000&Sort=Email&SortOrder=" + SortOrder.Ascending;
        this.logger.debug("HTTP GET users list.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response users list:", response);
        }), map(function (response) { return response.users; }));
    };
    // public getUsersSearch(searchQuery: string): Observable<PlannerUser[]> {
    //     const url = `${this.config.apiEndpoint}/api/private/UsersManagement/GetUsers?SearchQuery=${searchQuery}&Skip=0&Take=1000&Sort=Email&SortOrder=${SortOrder.Ascending}`;
    //     return this.getUsers(url);
    // }
    UsersService.prototype.exportUsersList = function (usersFilter) {
        var _this = this;
        var filterQuery = this.createFilterUrlParams(usersFilter);
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/ExcelExportUsers?" + filterQuery;
        this.logger.debug("HTTP GET export users list.", "Url: " + url);
        var request = new HttpRequest('GET', url, {
            reportProgress: false,
            responseType: 'blob'
        });
        return this.http.request(request)
            .pipe(tap(function (event) {
            _this.logger.debug("Response export users list:", event);
            console.log(event);
            if (event.type === HttpEventType.Response) {
                //this.logger.info(event.body);
                console.log(event.headers); // .headers('Content-Disposition');
                FileSaver.saveAs(event.body, "export-users.csv");
                return null;
            }
            return null;
        }));
    };
    UsersService.prototype.getOnlineUsers = function () {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/GetOnlineUsers";
        this.logger.debug("HTTP GET online users.", "Url: " + url);
        return this.http.get(url)
            .pipe(tap(function (response) {
            _this.logger.debug("Response online users:", response);
        }), map(function (response) { return response.onlineUsers; }));
    };
    UsersService.prototype.editUser = function (editCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/EditUser";
        this.logger.debug("HTTP POST edit user", "url: " + url, editCommand);
        return this.http.post(url, editCommand)
            .pipe(tap(function (response) { return _this.logger.debug("Response edit user:", response); }), catchError(function (err) {
            _this.logger.error(err);
            _this.logger.error(err.status);
            return throwError(err);
        }));
    };
    UsersService.prototype.deleteUser = function (deleteCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/DeleteUser";
        this.logger.debug("HTTP POST edit user", "url: " + url, deleteCommand);
        return this.http.post(url, deleteCommand)
            .pipe(tap(function (response) { return _this.logger.debug("Response delete user:", response); }), catchError(function (err) {
            _this.logger.error(err);
            _this.logger.error(err.status);
            return throwError(err);
        }));
    };
    UsersService.prototype.createWebLinkToken = function (createWebLinkTokenCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/CreateWebLinkToken";
        this.logger.debug("HTTP POST create web link", "url: " + url, createWebLinkTokenCommand);
        return this.http.post(url, createWebLinkTokenCommand)
            .pipe(tap(function (response) { return _this.logger.debug("Response create web link:", response); }));
    };
    UsersService.prototype.revokeWebLinkToken = function (revokeWebLinkTokenCommand) {
        var _this = this;
        var url = this.config.apiEndpoint + "/api/private/UsersManagement/RevokeWebLinkToken";
        this.logger.debug("HTTP POST revoke web link", "url: " + url, revokeWebLinkTokenCommand);
        return this.http.post(url, revokeWebLinkTokenCommand)
            .pipe(tap(function (response) { return _this.logger.debug("Response revoke web link:", response); }));
    };
    UsersService.prototype.createFilterUrlParams = function (usersFilter) {
        var filterQuery = "isWithAdmins=" + usersFilter.isWithAdmins + "&medicalReferals=" + (usersFilter.medicalReferals ? usersFilter.medicalReferals : '') + "&speciality=" + (usersFilter.speciality ? usersFilter.speciality : '') + "&language=" + (usersFilter.language ? usersFilter.language : '') + "&searchQuery=" + (usersFilter.searchQuery ? usersFilter.searchQuery : '');
        return filterQuery;
    };
    UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.APP_CONFIG), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.NGXLogger)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
