import { selectUsers } from '../store/selectors/users.selectors';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of, forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ResetUsersFilter } from '../store/actions/users.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
var UsersResolver = /** @class */ (function () {
    function UsersResolver(_store, logger) {
        this._store = _store;
        this.logger = logger;
    }
    UsersResolver.prototype.resolve = function (route, state) {
        var routeParams = route.params;
        this.logger.info('Users route resolver: ', route.params);
        var obsCollection = [];
        obsCollection.push(this._store.select(selectUsers).pipe(filter(function (allUsers) { return allUsers != null; }), take(1)));
        obsCollection.push(of(this._store.dispatch(new ResetUsersFilter({ isWithAdmins: false }))));
        return forkJoin(obsCollection);
    };
    UsersResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersResolver_Factory() { return new UsersResolver(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: UsersResolver, providedIn: "root" });
    return UsersResolver;
}());
export { UsersResolver };
