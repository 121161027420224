import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
//import { Mail } from '../mail.model';
//import { MailService } from '../mail.service';
import { PlannerCase, FileType, SceneObjectType, PlannerFile, GetPlannerFileSASQuery, PlannerSceneObjectOperationArgs as PlannerSceneObjectOperationArgs, ObjectOperationType, CaseStatus, FileResponse, PlannerSceneVisibilityGroup, MaterialQuality, OperationType, PlannerCaseShare, PlannerCaseBundle } from 'app/core/models';
import { PlannerScene } from 'app/core/models/planner/plannerScene';
import { FilesService } from 'app/core/services/planner/files.sevice';
import { DownloadDialogComponent } from 'app/core/shared/dialog-download/download-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddFileDialogComponent } from '../dialogs/add-file/add-file.component';
import { EditSceneObjectDialogComponent } from '../dialogs/edit-scene-object/edit-scene-object.component';
import { PlannerSceneObject } from 'app/core/models/planner/plannerSceneObject';
import { NGXLogger } from 'ngx-logger';
import { CaseCloneDialogComponent } from '../dialogs/case-clone/case-clone.component';
import { ConfirmDialogComponent } from 'app/core/shared';
import { CaseEditDialogComponent } from '../dialogs/case-edit/case-edit.component';
import { EditVisabilityGroupsComponent } from '../dialogs/edit-visability-groups/edit-visability-groups.component';
import { CaseShareDialogComponent } from '../dialogs/case-share/case-share.component';
import { EditFileDialogComponent } from '../dialogs/edit-file/edit-file.component';
import { EditFilePermissionsComponent } from '../dialogs/edit-file-permissions/edit-file-permissions.component';
import { CaseWebLinkDialogComponent } from '../dialogs/case-weblink/case-weblink.component';
import * as moment from 'moment';
import { AppConfig } from 'app/app-config.module';
import { ConvertType } from 'app/core/models/planner/enums/fileEnums';
import { EditBundleComponent } from '../dialogs/edit-bundle/edit-bundle.component';
var CasesDetailsComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MailService} _mailService
     */
    function CasesDetailsComponent(config, _downloadFileDialog, _matDialog, 
    //   private _casesService: CasesService,
    _filesService, logger) {
        this.config = config;
        this._downloadFileDialog = _downloadFileDialog;
        this._matDialog = _matDialog;
        this._filesService = _filesService;
        this.logger = logger;
        //statusSelectShown: boolean;
        this.caseStatusEnum = CaseStatus;
        this.panelOpenStateObject3d = true;
        this.objectTypeEnum = SceneObjectType;
        this.operationTypeEnum = OperationType;
        this.fileTypeEnum = FileType;
        // Set the defaults
        this.showDetails = false;
        this.selectedFiles = [];
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.onSceneSelected = new EventEmitter();
        this.onFilesUploaded = new EventEmitter();
        this.onFilesUploadedAndAdded = new EventEmitter();
        this.onSceneObjectEvent = new EventEmitter();
        this.onSceneVisibilityGroupsEvent = new EventEmitter();
        this.onFilesPermissionsEvent = new EventEmitter();
        //this.onCloneClick = new EventEmitter();
        this.onChatClick = new EventEmitter();
        this.onSaveScene = new EventEmitter();
        this.onRollbackToScene = new EventEmitter();
        this.onUsersSearch = new EventEmitter();
        this.onCaseEdit = new EventEmitter();
        this.onCaseClone = new EventEmitter();
        this.onCaseSharesUpdate = new EventEmitter();
        this.onCreateCaseWebLink = new EventEmitter();
        this.onRevokeCaseWebLink = new EventEmitter();
        this.onCaseDelete = new EventEmitter();
        this.onPlannerFileEdit = new EventEmitter();
        this.onPlannerFileDelete = new EventEmitter();
        this.onBundleSaved = new EventEmitter();
        this.onNotification = new EventEmitter();
        this.users$ = new Subject();
    }
    Object.defineProperty(CasesDetailsComponent.prototype, "selectedCase", {
        get: function () {
            return this._selectedCase;
        },
        set: function (value) {
            this._selectedCase = value;
            this.selectedFiles = [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CasesDetailsComponent.prototype, "users", {
        set: function (value) {
            this.users$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    CasesDetailsComponent.prototype.ngOnInit = function () {
    };
    /**
     * On destroy
     */
    CasesDetailsComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    CasesDetailsComponent.prototype.sceneSelected = function (sceneId) {
        //this.logger.info('scene selected: ' + sceneId);
        this.onSceneSelected.emit(sceneId);
        //   this._casesService.selectScene(sceneId);
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    CasesDetailsComponent.prototype.sceneObjectShowHideClicked = function (sceneObject) {
        this.logger.info('Scene object edit');
        sceneObject.isVisible = !sceneObject.isVisible;
        var plannerSceneObjectEditArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Edit);
        this.onSceneObjectEvent.emit(plannerSceneObjectEditArgs);
    };
    CasesDetailsComponent.prototype.sceneObjectEditClicked = function (sceneObject) {
        this.editSceneObjectDialog(sceneObject);
    };
    CasesDetailsComponent.prototype.sceneObjectDeleteClicked = function (sceneObject) {
        this.logger.info('Scene object delete');
        var plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Delete);
        this.onSceneObjectEvent.emit(plannerSceneObjectAddArgs);
    };
    CasesDetailsComponent.prototype.editSceneObjectDialog = function (sceneObject) {
        var _this = this;
        this.logger.info('add scene object dialog');
        this.editSceneObjectDialogRef = this._matDialog.open(EditSceneObjectDialogComponent, {
            panelClass: 'sceneObject-edit-dialog',
            data: { selectedCase: this.selectedCase, sceneObject: sceneObject }
        });
        this.editSceneObjectDialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var sceneObject = response[1];
            switch (actionType) {
                /**
                 * Create
                 */
                case 'add':
                    _this.logger.info('Scene object add');
                    var plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                    _this.onSceneObjectEvent.emit(plannerSceneObjectAddArgs);
                    break;
                case 'edit':
                    _this.logger.info('Scene object edit');
                    var plannerSceneObjectEditArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Edit);
                    _this.onSceneObjectEvent.emit(plannerSceneObjectEditArgs);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.editVisabilityGroupsDialog = function () {
        var _this = this;
        var editVisabilityGroupsDialogRef = this._matDialog.open(EditVisabilityGroupsComponent, {
            panelClass: 'visbilityGroups-edit-dialog',
            data: { visibilityGroups: this.selectedScene.visibilityGroups, sceneObjects: this.selectedScene.sceneObjects }
        });
        editVisabilityGroupsDialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var visabilityGroups = response[1];
            switch (actionType) {
                /**
                 * Create
                 */
                case 'save':
                    _this.logger.info('Scene object add');
                    //  const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                    _this.onSceneVisibilityGroupsEvent.emit(visabilityGroups);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.editFilePermissionsDialog = function () {
        var _this = this;
        var editFilePermissionsDialogRef = this._matDialog.open(EditFilePermissionsComponent, {
            panelClass: 'filePermissions-edit-dialog',
            data: { visibilityGroups: this.selectedScene.visibilityGroups, caseFiles: this.selectedCase.details.files }
        });
        editFilePermissionsDialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var filesAllowUserDownload = response[1];
            switch (actionType) {
                /**
                 * Create
                 */
                case 'save':
                    _this.logger.info('Scene object add');
                    //  const plannerSceneObjectAddArgs = new PlannerSceneObjectOperationArgs(sceneObject, ObjectOperationType.Add);
                    _this.onFilesPermissionsEvent.emit(filesAllowUserDownload);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.onCaseStatusChange = function (caseStatus) {
        this.selectedScene.caseSceneStatus = caseStatus;
    };
    CasesDetailsComponent.prototype.editCase = function () {
        var _this = this;
        var caseEditDialog = this._matDialog.open(CaseEditDialogComponent, {
            panelClass: 'case-edit-dialog',
            data: this.selectedCase
        });
        caseEditDialog.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var plannerCase = response[1];
            switch (actionType) {
                /**
                 * Create
                 */
                case 'edit':
                    _this.logger.info('Case edited, case :', plannerCase);
                    _this.onCaseEdit.emit(plannerCase);
                    //this.onCaseCreateFormGroup.emit(plannerCaseFormGroup);
                    break;
            }
        });
        //this.onCaseEdit.emit(this.selectedCase);
    };
    CasesDetailsComponent.prototype.cloneCase = function () {
        //this.onCloneClick.emit(this.selectedCase.id);
        var _this = this;
        var dialogRef = this._matDialog.open(CaseCloneDialogComponent, {
            panelClass: 'case-clone-dialog',
            data: {
                title: this.selectedCase.title
            }
        });
        //users
        var instance = dialogRef.componentInstance;
        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (users) {
            instance.users = users;
        });
        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (query) {
            _this.onUsersSearch.emit(query);
        });
        dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var newTitle = response[1];
            var selectedUser = response[2];
            switch (actionType) {
                case 'clone':
                    _this.onCaseClone.emit({ selectedCase: _this.selectedCase, newTitle: newTitle, selectedUser: selectedUser });
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.shareCase = function () {
        //this.onCloneClick.emit(this.selectedCase.id);
        var _this = this;
        var dialogRef = this._matDialog.open(CaseShareDialogComponent, {
            panelClass: 'case-share-dialog',
            data: {
                title: this.selectedCase.title,
                caseShares: this.selectedCaseShares
            }
        });
        //users
        var instance = dialogRef.componentInstance;
        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (users) {
            instance.users = users;
        });
        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(function (query) {
            _this.onUsersSearch.emit(query);
        });
        dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var usersToShare = response[1];
            switch (actionType) {
                case 'share':
                    _this.onCaseSharesUpdate.emit({ selectedCase: _this.selectedCase, usersToShare: usersToShare });
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.createCaseWebLink = function () {
        //this.onCloneClick.emit(this.selectedCase.id);
        var _this = this;
        var dialogRef = this._matDialog.open(CaseWebLinkDialogComponent, {
            panelClass: 'case-weblink-dialog',
            data: {
                title: this.selectedCase.title,
                selectCreatedWebLinkToken$: this.selectCreatedWebLinkToken$
                //  caseShares: this.selectedCaseShares
            }
        });
        var issuedUtc = moment(moment.now());
        var expiresUtc = issuedUtc.add(1, 'years');
        this.onCreateCaseWebLink.emit({
            caseId: this.selectedCase.id, userId: this.selectedCase.ownerId,
            issuedUtc: issuedUtc,
            expiresUtc: expiresUtc
        });
        dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var webLinkToken = response[1];
            var url = _this.config.visualizationEndpoint + "/" + webLinkToken;
            switch (actionType) {
                case 'copy':
                    _this.copyToClipboard(url);
                    break;
                case 'follow':
                    window.open(url, '_blank');
                    break;
                case 'revoke':
                    _this.onRevokeCaseWebLink.emit({
                        caseId: _this.selectedCase.id
                    });
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.deleteCase = function (selectedCaseId) {
        var _this = this;
        var confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: "Are you sure you want to delete case \"" + this.selectedCase.title + "\"?"
        });
        confirmDialogRef.afterClosed()
            .subscribe(function (confirmResult) {
            if (!confirmResult) {
                return;
            }
            _this.onCaseDelete.emit(selectedCaseId);
        });
    };
    CasesDetailsComponent.prototype.openChat = function () {
        this.onChatClick.emit(this.selectedCase.id);
    };
    CasesDetailsComponent.prototype.openBundle = function () {
        var _this = this;
        this.logger.info('Opening add file dialog');
        this.addBundleDialogRef = this._matDialog.open(EditBundleComponent, {
            panelClass: 'edit-bundle-dialog',
            data: { sceneObjects: this.selectedScene.sceneObjects, bundle: this.selectedCaseBundle }
        });
        this.addBundleDialogRef.afterClosed()
            .subscribe(function (response) {
            _this.logger.info('Upload file dialog was closed');
            if (!response) {
                return;
            }
            var actionType = response[0];
            var bundleFormArray = response[1];
            switch (actionType) {
                case 'save':
                    _this.onBundleSaved.emit(bundleFormArray);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.saveScene = function () {
        this.onSaveScene.emit(this.selectedScene);
    };
    CasesDetailsComponent.prototype.rollbackToScene = function () {
        this.onRollbackToScene.emit(this.selectedScene);
    };
    CasesDetailsComponent.prototype.downloadFile = function (plannerFile, convertType) {
        var _this = this;
        var fileId = plannerFile.id;
        var fileName = plannerFile.fileName;
        if (!convertType && plannerFile.fileType == FileType.STLMODEL)
            convertType = ConvertType.PlyToStl;
        var sub = this._filesService.downloadFile(this.selectedCase.id, fileId, fileName, convertType)
            .pipe(takeUntil(this._unsubscribeAll));
        var dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.logger.info('The dialog was closed');
        });
    };
    CasesDetailsComponent.prototype.selectFile = function (plannerFile) {
        var selectedFile = this.selectedFiles.find(function (x) { return x.id == plannerFile.id; });
        if (!selectedFile) {
            this.selectedFiles.push(plannerFile);
        }
        else {
            var selectedFileIndex = this.selectedFiles.indexOf(selectedFile);
            this.selectedFiles.splice(selectedFileIndex, 1);
        }
    };
    CasesDetailsComponent.prototype.deleteFile = function (plannerFile) {
        var _this = this;
        var confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
            panelClass: 'dialog-confirm',
            data: "Are you sure you want to delete file \"" + plannerFile.title + "\"?"
        });
        confirmDialogRef.afterClosed()
            .subscribe(function (confirmResult) {
            if (!confirmResult) {
                return;
            }
            _this.onPlannerFileDelete.emit(plannerFile);
        });
    };
    CasesDetailsComponent.prototype.downloadFiles = function (files) {
        var _this = this;
        var getFilesQuery = new Array();
        files.forEach(function (file) {
            getFilesQuery.push({ FileId: file.id, FileName: file.fileName });
        });
        var sub = this._filesService.downloadFiles(this.selectedCase.id, getFilesQuery)
            .pipe(takeUntil(this._unsubscribeAll));
        var dialogRef = this._downloadFileDialog.open(DownloadDialogComponent, {
            width: '250px',
            data: sub
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.logger.info('Download files dialog was closed');
            //this.animal = result;
        });
        this.selectedFiles = [];
    };
    CasesDetailsComponent.prototype.addFileDialog = function () {
        var _this = this;
        this.logger.info('Opening add file dialog');
        this.addFileDialogRef = this._matDialog.open(AddFileDialogComponent, {
            panelClass: 'file-add-dialog',
            data: this.selectedCase
        });
        this.addFileDialogRef.afterClosed()
            .subscribe(function (response) {
            _this.logger.info('Upload file dialog was closed');
            if (!response) {
                return;
            }
            var actionType = response[0];
            switch (actionType) {
                case 'upload':
                    _this.onFilesUploaded.emit();
                    break;
                case 'upload&add':
                    var fileResponses = response[2];
                    var plannerSceneObjects_1 = [];
                    fileResponses.forEach(function (fileResponse) {
                        var sceneObjectFromFile = new PlannerSceneObject();
                        sceneObjectFromFile.objectType = SceneObjectType.Object3d;
                        sceneObjectFromFile.isVisible = true;
                        sceneObjectFromFile.materialQuality = MaterialQuality.Good;
                        sceneObjectFromFile.objectFile = new PlannerFile();
                        sceneObjectFromFile.objectFile.id = fileResponse.fileId;
                        sceneObjectFromFile.title = fileResponse.fileName;
                        plannerSceneObjects_1.push(sceneObjectFromFile);
                    });
                    _this.onFilesUploadedAndAdded.emit(plannerSceneObjects_1);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.editFileDialog = function (plannerFile) {
        var _this = this;
        this.logger.info('Opening add file dialog');
        this.addFileDialogRef = this._matDialog.open(EditFileDialogComponent, {
            panelClass: 'file-edit-dialog',
            data: { selectedCase: this.selectedCase, plannerFile: plannerFile }
        });
        this.addFileDialogRef.afterClosed()
            .subscribe(function (response) {
            _this.logger.info('Edit file dialog was closed');
            if (!response) {
                return;
            }
            var actionType = response[0];
            switch (actionType) {
                case 'edit':
                    _this.onPlannerFileEdit.emit(response[1]);
                    break;
            }
        });
    };
    CasesDetailsComponent.prototype.isLastScene = function () {
        if (!this.selectedCase || !this.selectedCase.scenes || !this.selectedScene)
            return false;
        var scenesCount = this.selectedCase.scenes.length;
        if (scenesCount == 0)
            return false;
        return this.selectedCase.scenes[scenesCount - 1].id == this.selectedScene.id ? true : false;
    };
    CasesDetailsComponent.prototype.copyToClipboard = function (val) {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.onNotification.emit("Copied to clipboard!");
    };
    return CasesDetailsComponent;
}());
export { CasesDetailsComponent };
